import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons";
import { faDrawPolygon } from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import History from "../bottom_right_menu/History";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import {
  addDataToMap,
  removeDataset,
  deleteFeature,
  toggleSidePanel,
} from "kepler.gl/actions";
import CountriesData from "../../sampledata/countries.json";
import { bordersConfig } from "../../map-configs";
import HelpText from "../HelpText";
import styled from "styled-components";
import tractebelGrey from "../../assets/tractebel-grey.jpg";
import { HeatmapHelpModal, LocationsHelpModal } from "../HelpModals";
import "./styles.scss";

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 64px;
  color: #6a7485;
  align-self: center;
  &:hover {
    color: #0b74c0;
  }
`;

const container = {
  display: "flex",
  flex: 1,
  maxHeight: 100,
  flexDirection: "column",
  color: "#000000",
  justifyItems: "center",
  justifyContent: "space-around",
  textAlign: "center",
};

const Locations = (props) => {
  const [pickupLocation, setPickupLocation] = React.useState(false);
  const [currentCoord, setCurrentCoord] = React.useState();

  const addLocation = () => {
    props.dispatch({
      type: "ADD_LOCATION",
    });
  };

  const updateLocation = (id, lng, lat, name) => {
    props.dispatch({
      type: "UPDATE_LOCATION",
      payload: { id, lng, lat, name },
    });
  };

  const removeLocation = (id) => {
    props.dispatch({
      type: "REMOVE_LOCATION",
      payload: id,
    });
  };

  const geolocations = props.formReducer.zoneSelectionData.locations;

  if (pickupLocation) {
    if (props.appReducer.coordinate) {
      const [lng, lat] = props.appReducer.coordinate;
      if (
        geolocations &&
        !geolocations.filter((gl) => gl.lng === lng && gl.lat === lat).length &&
        currentCoord !== props.appReducer.coordinate
      ) {
        props.dispatch({
          type: "ADD_LOCATION",
          payload: { lng, lat },
        });
        setPickupLocation(false);
      }
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
      <LocationsHelpModal />
      <>
        <div style={{ flex: 1 }}>
          <form noValidate autoComplete="off">
            {geolocations.map((geolocation) => (
              <div
                key={geolocation.id}
                style={{ display: "flex", alignItems: "center" }}
              >
                <TextField
                  label="Name"
                  placeholder="New Site"
                  size="small"
                  onChange={(e) =>
                    updateLocation(
                      geolocation.id,
                      geolocation.lng,
                      geolocation.lat,
                      e.target.value
                    )
                  }
                  value={geolocation.name}
                  style={{ paddingRight: 4 }}
                />
                <TextField
                  label="Latitude"
                  placeholder="51.260197"
                  size="small"
                  error={
                    !(
                      isFinite(geolocation.lat) &&
                      Math.abs(geolocation.lat) <= 90
                    )
                  }
                  onChange={(e) =>
                    updateLocation(
                      geolocation.id,
                      geolocation.lng,
                      e.target.value,
                      geolocation.name
                    )
                  }
                  value={geolocation.lat}
                  style={{ paddingRight: 4 }}
                />
                <TextField
                  label="Longitude"
                  placeholder="4.402771"
                  error={
                    !(
                      isFinite(geolocation.lng) &&
                      Math.abs(geolocation.lng) <= 180
                    )
                  }
                  onChange={(e) =>
                    updateLocation(
                      geolocation.id,
                      e.target.value,
                      geolocation.lat,
                      geolocation.name
                    )
                  }
                  value={geolocation.lng}
                  size="small"
                  style={{ paddingRight: 4 }}
                />
                <IconButton onClick={() => removeLocation(geolocation.id)}>
                  <Icon style={{ color: "#fb5c5c" }}>close</Icon>
                </IconButton>
              </div>
            ))}
          </form>
          <div
            style={{ width: "100%" }}
            onClick={() => addLocation({ id: uuidv4(), lat: "", lng: "" })}
            className="run-button"
          >
            ADD A NEW LOCATION
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: pickupLocation ? "#fb5c5c" : "#009de9",
            }}
            onClick={() => {
              props.dispatch({
                type: "SET_ZONE_OR_COUNTRY",
                payload: { country: false, zone: false },
              });
              if (
                props.keplerReducer.map.uiState.mapControls.mapDraw.active ===
                true
              ) {
                props.dispatch({
                  type: "@@kepler.gl/TOGGLE_MAP_CONTROL",
                  payload: {
                    panelId: "mapDraw",
                    index: 0,
                  },
                });
              }
              setPickupLocation((o) => {
                setCurrentCoord(props.appReducer.coordinate);
                return !o;
              });
            }}
            className="run-button"
          >
            {!pickupLocation ? "PICK LOCATION" : "STOP PICK LOCATION"}
          </div>
        </div>
      </>
      <div className="button-wrapper" style={{ justifyContent: "flex-end" }}>
        <div />
        <div
          onClick={() => props.dispatch(toggleSidePanel("renewable-sizing"))}
          className="run-button"
        >
          Next
        </div>
      </div>
    </div>
  );
};

const Region = (props) => (
  <>
    <HeatmapHelpModal />
    <div
      style={{
        textAlign: "center",
        fontSize: "1.2em",
        marginTop: 12,
        marginBottom: 4,
        fontWeight: "bold",
      }}
    >
      Choose selection type:
    </div>
    <div
      className="zone-selector"
      style={{
        height: "inherit",
        display: "flex",
        flex: 1,
      }}
    >
      <HelpText element="Country">
        <div
          onClick={() => props.setHistoryOpen(true)}
          className={
            props.formReducer.zoneSelectionData.country === true
              ? "country-type-container active"
              : "country-type-container"
          }
          style={container}
        >
          <StyledIcon icon={faGlobeAmericas} />
          <div className="country-title">Country</div>
        </div>
      </HelpText>
      <HelpText element="Zone">
        <div
          onClick={() => props.setHistoryOpen(true)}
          className={
            props.formReducer.zoneSelectionData.zone === true
              ? "area-type-container active"
              : "area-type-container"
          }
          style={container}
        >
          <StyledIcon icon={faDrawPolygon} />
          <div className="zone-title">Zone</div>
        </div>
      </HelpText>
    </div>

    <div>
      <a
        href="https://digital.tractebel-engie.com/tractebel-calculators-disclaimer/"
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        Disclaimer
      </a>
    </div>

    <div className="button-wrapper">
      <div />
      <div
        onClick={() => props.dispatch(toggleSidePanel("renewable-sizing"))}
        className="run-button"
      >
        Next
      </div>
    </div>
  </>
);

const ZonePanelComp = (props) => {
  const [isHistoryOpen, setHistoryOpen] = React.useState(false);
  const toggleChecked = (e) => {
    props.dispatch(removeDataset("countries"));
    props.dispatch({
      type: "SET_ZONE_OR_COUNTRY",
      payload: { country: false, zone: false },
    });
    props.dispatch({
      type: "SET_HEATMAP_OR_LOCATIONS",
      payload: e.target.checked,
    });
  };

  const setConfigurationName = (name) => {
    props.dispatch({ type: "SET_ZONE_OR_COUNTRY", payload: { name } });
  };

  return (
    <>
      {isHistoryOpen ? (
        <History
          dispatch={props.dispatch}
          setHistoryVisible={() => {
            setHistoryOpen(!isHistoryOpen);
          }}
          toggleToSidePanel={() => {
            props.dispatch(toggleSidePanel("renewable-sizing"));
            setHistoryOpen(!isHistoryOpen);
          }}
        />
      ) : null}
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <TextField
          label="Simulation name"
          placeholder="Name"
          size="small"
          onChange={(e) => setConfigurationName(e.target.value)}
          value={props.formReducer.zoneSelectionData.name}
          style={{ marginBottom: 8 }}
          InputLabelProps={{ style: { fontSize: "1.2rem" } }}
        />
        <div className="switch-container">
          <HelpText element="Heatmap">
            <div className="heatmap-switch">HEATMAP</div>
          </HelpText>
          <Switch
            onChange={(e) => toggleChecked(e)}
            checked={props.uiStateReducer.is_locations}
            className="custom-switch"
            disableRipple
            disableFocusRipple
          />
          <HelpText element="Locations">
            <div className="locations-switch">LOCATIONS</div>
          </HelpText>
        </div>
        {props.uiStateReducer.is_locations ? (
          <Locations setHistoryOpen={setHistoryOpen} {...props} />
        ) : null}

        {!props.uiStateReducer.is_locations ? (
          <Region setHistoryOpen={setHistoryOpen} {...props} />
        ) : null}

        <div style={{ padding: "14px 30px", textAlign: "center" }}>
          <img src={tractebelGrey} style={{ maxWidth: "50%" }} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formReducer: state.formReducer,
    uiStateReducer: state.uiStateReducer,
    appReducer: state.appReducer,
    keplerReducer: state.keplerGl,
  };
};
const dispatchToProps = (dispatch) => ({ dispatch });

export const ZonePanel = connect(
  mapStateToProps,
  dispatchToProps
)(ZonePanelComp);
