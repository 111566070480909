const chile = {
  grid_distance: 24.018,
  LCOE: 948.60,
  wind_capacity_factor: 0.022221533905548336,
  solar_capacity_factor: 0.2578116378680094,
  average_capacity_factor: 0.04392218295420672,
  mix: 0.9999970195695067,
  wind_monthly_capacity_factor: [
    0.009811281796037241, 0.004762549391112163, 0.01460210196676171,
    0.03828422083201374, 0.08745357905696395, 0.05389158661194935,
    0.0847521503309242, 0.03705982888469173, 0.06623332175649128,
    0.05979279403533234, 0.03382241693716245, 0.033336719040433865,
  ],
  solar_monthly_capacity_factor: [
    0.20441481558791075, 0.20683784543658676, 0.26513523511560527,
    0.2777053716025728, 0.2830329168804645, 0.2677870315883514,
    0.2787584705234311, 0.29145271580285814, 0.2789945216703432,
    0.28397086038289343, 0.25320146650562203, 0.2446974505001376,
  ],
  wind_hourly_capacity_factor: [
    0.00972806744844884, 0.009317798826171758, 0.007686182939817904,
    0.006932176319599549, 0.0068038042114763305, 0.00617075132679231,
    0.005533273556345379, 0.005354397581437713, 0.005199133386515608,
    0.004934290808739561, 0.003965310029090745, 0.004989113520998174,
    0.004849559383441723, 0.006832565827912037, 0.02529343284492697,
    0.05369188872802772, 0.08572401248998548, 0.11965835517148467,
    0.1538170803817377, 0.1714895180611476, 0.16606599996421517,
    0.1230705532665013, 0.04905101085662595, 0.0179585368017197,
  ],
  solar_hourly_capacity_factor: [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.006209891730786733, 0.13808813907340323,
    0.3942216439964642, 0.6138689526385355, 0.7670778107141794,
    0.8433349799415187, 0.8518699852970651, 0.8180857175727166,
    0.7338118332055811, 0.5852215280463455, 0.3874669695770836,
    0.14120442369661132, 0.00013743334193536713,
  ],
  slope: 7.9755477907194265,
  country: "Chile",
  temperature: 5.842312593455915,
  wind_speed: 2.981162667274475,
  ghi: 2626.1750626564026,
  wind_capex: 1446.3,
  solar_capex: 1123.7,
  wind_opex: 28.9,
  solar_opex: 17.3,
  electricity_tarif: "10.2",
  electricity_ranking: "39",
  grid_connection_time: "43",
  layers: {
    urban: false,
    protected_area: false,
    forest: false,
    airports: false,
    complex_terrain: false,
    water: false,
    wetland: false,
  },
};

export default chile;
