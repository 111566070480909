import { v4 as uuidv4 } from "uuid";
import keplerGlReducer from "kepler.gl/reducers";
import { handleActions } from "redux-actions";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { taskMiddleware } from "react-palm/tasks";
import { ActionTypes } from "kepler.gl/actions";
import snackbarReducer from "./reducers/snackbarReducer";
import geoJSONReducer from "./reducers/geoJSONReducer";
import {
  SET_API_LOADING_HEATMAP,
  SET_API_LOADING_LOCATION_DATA,
} from "./constants";

// Hiding the upload modal by default
const customInitialKeplerReducer = keplerGlReducer.initialState({
  uiState: {
    // Hide modals
    currentModal: null,
    activeSidePanel: "zone",
    mapControls: {
      mapLegend: {
        show: true,
        active: false,
      },
      toggle3d: {
        show: true,
        active: false,
      },
      splitMap: {
        show: false,
        active: false,
      },
      mapDraw: {
        show: false,
        active: false,
      },
    },
  },
});

// Adding a plugin to base kepler reducer
const myKeplerGlReducer = customInitialKeplerReducer.plugin(
  handleActions(
    {
      REORDER_LAYERS: (state, action) => {
        return {
          ...state,
          visState: {
            ...state.visState,
            layerOrder: state.visState.layerOrder.map((item, index) => index),
          },
        };
      },
    },
    {}
  )
);

// function to find the longest array
const indexOfLongestArray = (arrays) => {
  if (arrays !== null) {
    let max = 0;
    let indexOfMax = 0;
    for (let i = arrays.length - 1; i >= 0; i--) {
      if (arrays[i][0].length > max) {
        max = arrays[i][0].length;
        indexOfMax = i;
      }
    }
    return indexOfMax;
  }
};

const appReducer = handleActions(
  {
    // listen on kepler.gl map update action to store the clicked polygon in state
    [ActionTypes.LAYER_CLICK]: (state, action) => {
      let indexOfMainland = indexOfLongestArray(
        action?.payload?.info?.object?.geometry?.coordinates || null
      );
      let type = action?.payload?.info?.object?.geometry?.type || null;
      let poly = {};
      if (type === "Polygon") {
        poly =
          action?.payload?.info?.object?.geometry?.coordinates[indexOfMainland];
      } else if (type === "MultiPolygon") {
        poly =
          action?.payload?.info?.object?.geometry?.coordinates[
            indexOfMainland
          ][0];
      } else {
        return { ...state, coordinate: action.payload.info.coordinate };
      }
      return {
        ...state,
        selectedPolygon: poly,
      };
    },
    [ActionTypes.SET_EDITOR_MODE]: (state, action) => {
      return state;
    },
    [ActionTypes.SET_FEATURES]: (state, action) => {
      let poly = action.payload.features[0].geometry.coordinates[0];
      return {
        ...state,
        selectedPolygon: poly,
      };
    },
    CUSTOM_DELETE_POLYGON: (state, action) => {
      return {
        ...state,
        selectedPolygon: null,
      };
    },
  },
  {}
);

const formReducer = (
  state = {
    user: {
      email: null,
      license_type: null,
      user_group: null,
      user_id: null,
      company: null,
    },
    zoneSelectionData: {
      name: "",
      country: false,
      zone: false,
      locations: [],
    },
    renewableTechFormData: {
      solar: false,
      wind: false,
      pv_tracking: "fixed", // can be fixed or single axis if solar is true
      opti: "stability",
      combined_slider: 0.5,
    },
    exclusionAreasFormData: {
      airports: 500,
      complex_terrain: 15,
      hv_line_distance: 1,
      forest: 0,
      protected_area: 0,
      urban: 0,
      substation_distance: 1,
      ui_show_complex_terrain: false,
      ui_show_hv_line_distance: false,
      ui_show_substation_distance: false,
      is_forest: false,
      is_airports: false,
      is_protected_area: false,
      is_urban: false,
      water: true,
    },
    advancedLCOEFormData: {
      grid_connection_type: "substation",
      grid_cost_per_km: { checked: false, value: "" },
      slope_cost_per_percent: { checked: false, value: "" },
      wind_capex: "",
      solar_capex: "",
      wind_opex: "",
      solar_opex: "",
      lifetime: "",
      wacc: "",
    },
  },
  action
) => {
  if (action.type === "SET_USER_DATA") {
    return {
      ...state,
      user: action.payload,
    };
  }
  if (action.type === "SET_ZONE_OR_COUNTRY") {
    return {
      ...state,
      zoneSelectionData: { ...state.zoneSelectionData, ...action.payload },
    };
  } else if (action.type === "ADD_LOCATION") {
    return {
      ...state,
      zoneSelectionData: {
        ...state.zoneSelectionData,
        locations: [
          ...state.zoneSelectionData.locations,
          {
            id: uuidv4(),
            lat: action?.payload?.lat || "",
            lng: action?.payload?.lng || "",
            name: `Site ${state.zoneSelectionData.locations.length + 1}`,
          },
        ],
      },
    };
  } else if (action.type === "UPDATE_LOCATION") {
    const { id, lng, lat, name } = action.payload;
    const locations = state.zoneSelectionData.locations.map((nl) =>
      nl.id === id ? { id, lng, lat, name } : nl
    );
    return {
      ...state,
      zoneSelectionData: { ...state.zoneSelectionData, locations },
    };
  } else if (action.type === "REMOVE_LOCATION") {
    const locations = state.zoneSelectionData.locations.filter(
      (nl) => nl.id !== action.payload
    );
    return {
      ...state,
      zoneSelectionData: { ...state.zoneSelectionData, locations },
    };
  } else if (action.type === "SET_FORM_RENEWABLE_TECH") {
    return {
      ...state,
      renewableTechFormData: {
        ...state.renewableTechFormData,
        ...action.payload,
      },
    };
  } else if (action.type === "SET_FORM_EXCLUSION_AREAS") {
    let newState = Object.assign(
      { ...state.exclusionAreasFormData },
      action.payload
    );
    return {
      ...state,
      exclusionAreasFormData: newState,
    };
  } else if (action.type === "SET_FORM_ADVANCED_LCOE") {
    let newState = Object.assign(
      { ...state.advancedLCOEFormData },
      action.payload
    );
    return {
      ...state,
      advancedLCOEFormData: newState,
    };
  } else if (action.type === "SET_HISTORY_INPUTS") {
    return {
      ...state,
      historyInputs: action.payload,
    };
  } else if (action.type === "REMOVE_HISTORY_INPUTS") {
    let s = { ...state };
    delete s.historyInputs;
    return s;
  }

  return state;
};

const uiStateReducer = (state = { is_locations: false }, action) => {
  if (action.type === "SET_HEATMAP_OR_LOCATIONS") {
    return {
      ...state,
      is_locations: action.payload,
    };
  }
  if (action.type === "SET_HELP_MODAL") {
    return {
      ...state,
      modal: action.payload,
    };
  }
  return state;
};

const loadingReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_API_LOADING_HEATMAP:
      return {
        isApiLoadingHeatmap: action.payload,
      };
    case SET_API_LOADING_LOCATION_DATA:
      return {
        isApiLoadingLocationData: action.payload,
      };

    default:
      return state;
  }
};

// Adding Kepler reducers to our own
const reducers = combineReducers({
  keplerGl: myKeplerGlReducer,
  appReducer,
  loadingReducer,
  formReducer,
  uiStateReducer,
  // Prepared place for our own reducers for later
  snackbarReducer,
  geoJSONReducer,
});

export const enhancers = [applyMiddleware(taskMiddleware)];

// Added for debugging
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(reducers, {}, composeEnhancers(...enhancers));
