import styled from "styled-components";
import wisogis from "../assets/wisogisblue.png";
import tractebel from "../assets/tractebelsolo.png";
import "./style.scss";

const Placeholder = styled.div`
  min-width: 300px;
  min-height: 76px;
`;

export const CustomHeader = () => {
  return () => <Placeholder />;
};

const FixedHeaderContainer = styled.div`
  z-index: 999;
  position: fixed;
  top: 20px;
  left: 84px;
  justify-content: center;
`;

export const FixedHeader = () => (
  <FixedHeaderContainer>
    <div className="header-container">
      <img className="wisogis-logo" alt="Logo wisogis" src={wisogis}></img>
    </div>
  </FixedHeaderContainer>
);
