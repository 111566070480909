import {
  SNACKBAR_SUCCESS,
  SNACKBAR_INFO,
  SNACKBAR_ERROR,
  SNACKBAR_CLEAR,
} from "../constants";

export const showSuccessSnackbar = (message) => ({
  type: SNACKBAR_SUCCESS,
  message,
});

export const showInfoSnackbar = (message) => ({ type: SNACKBAR_INFO, message });

export const showErrorSnackbar = (message) => ({
  type: SNACKBAR_ERROR,
  message,
});

export const clearSnackbar = () => ({ type: SNACKBAR_CLEAR });
