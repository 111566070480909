const lebanon = {
  LCOE: 342.98,
  wind_capacity_factor: 0.056931330809062106,
  solar_capacity_factor: 0.2190139742877772,
  average_capacity_factor: 0.13394016313483498,
  mix: 0.6996123200304909,
  wind_monthly_capacity_factor: [
    0.2383994970805137, 0.18154168622541283, 0.1204273218027411,
    0.09726646098645299, 0.07188771250676099, 0.05791982249422328,
    0.13508314946988317, 0.07628440216370444, 0.030724802834680518,
    0.031528965400597936, 0.0626982591303529, 0.17357959271753548,
  ],
  solar_monthly_capacity_factor: [
    0.16514134749661896, 0.18856093395680867, 0.20745003467118414,
    0.2153176640727499, 0.23033792034931366, 0.2119322944486983,
    0.21528650735348703, 0.21835879116377957, 0.21140004231024762,
    0.18567247474536297, 0.18138169491177097, 0.14849465299264183,
  ],
  wind_hourly_capacity_factor: [
    0.10451928077509567, 0.101602410395345, 0.09856192945279302,
    0.0958540464008082, 0.09421680080458943, 0.09334166094565417,
    0.09547919019347649, 0.10126564170801355, 0.10659521149650189,
    0.10899816159325866, 0.1059269497848257, 0.10634007278933226,
    0.10781723891466967, 0.10918087818041874, 0.10986204891029847,
    0.11066432283572472, 0.1102307787285269, 0.11240216765818976,
    0.11547113883067363, 0.11589489366906604, 0.11677072997474222,
    0.11545412753247572, 0.10852315612532676, 0.10654655647509916,
  ],
  solar_hourly_capacity_factor: [
    0, 0, 0, -0.0000674407232876713, 0.009198582252987114, 0.08574677596310148,
    0.24738477714980514, 0.41218072783946386, 0.5433954022890671,
    0.6309909331406215, 0.6730366197202421, 0.6623266661442138,
    0.5958285860922499, 0.48450468653462686, 0.3272510404856921,
    0.08457353393223203, 0.0024644920856366857, 0, 0, 0, 0, 0, 0, 0,
  ],
  slope: 60.34766367069304,
  country: "Lebanon",
  temperature: 21.143771024956948,
  wind_speed: 4.432384729385376,
  ghi: 1907.8550863265991,
  wind_capex: 1515,
  solar_capex: 1008.281,
  wind_opex: 30.3,
  solar_opex: 10.08,
  electricity_tarif: "13.0",
  electricity_ranking: "127",
  grid_connection_time: "89",
  layers: {
    water: false,
  },
};

export default lebanon;
