import React from "react";
import styled from "styled-components";
import Auth from "@aws-amplify/auth";
import JSZip from "jszip";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { Storage } from "aws-amplify";
import { toggleModal, toggleMapControl } from "kepler.gl/actions";
import {
  CONTACT_US_EMAIL,
  PRIVACY_STATEMENT_LINK,
  TERMS_OF_USE,
} from "../../constants";
import History from "./History";
import LicensesModal from "./License";
import generateHeatmapPDF from "../../utils/generateResults";
import "./style.scss";

const fetchLayer = async (fileKey, identityId) => {
  const res_file = await Storage.get(fileKey, {
    level: "protected",
    identityId,
    contentType: "application/json",
    download: true,
  });

  return await new Response(res_file.Body).json();
};

const exportLayers = async (s3fileId, identityId) => {
  var zip = new JSZip();

  const listRes = await Storage.list(``, {
    level: "protected",
    identityId,
  });
  const filteredList = listRes.filter((res) => res.key.startsWith(s3fileId));
  for (const f of filteredList) {
    const res = await fetchLayer(f.key, identityId);
    zip.file(f.key, JSON.stringify(res));
  }
  zip.generateAsync({ type: "blob" }).then((content) => {
    saveAs(content, `${s3fileId}.zip`);
  });
};

const Tab = styled.div`
  min-width: 120px;
  right: 46px;
  text-align: left;
  background-color: #f7f7f7;
  position: absolute;
  min-height: 31px;
  vertical-align: middle;
  display: flex;
  padding-left: 4px;
  align-items: center;
  margin-right: 2px;
`;

const Settings = () => (
  <>
    <a href={`mailto:${CONTACT_US_EMAIL}`} target="_blank" rel="noreferrer">
      <Tab
        style={{
          top: -36,
          color: "#212121",
          cursor: "pointer",
        }}
      >
        Contact us
      </Tab>
    </a>
    <a href={PRIVACY_STATEMENT_LINK} target="_blank" rel="noreferrer">
      <Tab
        style={{
          top: -4,
          color: "#212121",
          cursor: "pointer",
        }}
      >
        Privacy statement
      </Tab>
    </a>
    <a href={TERMS_OF_USE} target="_blank" rel="noreferrer">
      <Tab
        style={{
          top: 28,
          color: "#212121",
          cursor: "pointer",
        }}
      >
        Terms of Use
      </Tab>
    </a>
  </>
);

const BottomRightMenu = ({ formReducer, geoJSONReducer }) => {
  const [isSettingsVisible, setSettingsVisible] = React.useState(false);
  return (
    <>
      <div className="topmenu-container">
        <div className="settings-menu">
          {isSettingsVisible && (
            <div>
              <Settings />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formReducer: state.formReducer,
    geoJSONReducer: state.geoJSONReducer,
  };
};

const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(BottomRightMenu);
