import { EXPORT_GEOJSON_LAYERS, SAVE_S3_FILE_ID } from "../constants";

const geoJSONReducer = (state = {}, action) => {
  switch (action.type) {
    case SAVE_S3_FILE_ID:
      return { s3fileId: action.payload };
    case EXPORT_GEOJSON_LAYERS:
      return state;
    default:
      return state;
  }
};

export default geoJSONReducer;
