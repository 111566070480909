import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";

export const tooltips = {
  // Zone Selection
  Heatmap:
    "This feature allows to calculate and display the LCOE variations in your area of interest with the parameters of your Project",
  Locations:
    "This feature allows you to calculate and display the LCOE specific to certain locations of interest around the world with the parameters of your Project",
  Country: "Selection of a country for the calculation of your heatmap",
  Zone: "Drawing of the area of interest for the calculation of your heatmap. After drawing the shape of your area, click on the first point of your polygon to close it. The smaller the area, the better the spatial resolution.",

  // Renewable Technology
  "Optimising renewable mix for":
    "Selection of technologies used for your project. Wisogis will optimize the energy mix to guarantee a high production stability or a low LCOE according to the selection made on the slider below. Please note that if only one technology is selected, no optimization can be done. ",
  "Fixed tilt":
    "The solar modules are installed on a fixed structure. The tilt of the modules is optimized by Wisogis. ",
  "Single axis tracking":
    "The solar modules are installed on a mobile structure that follows the path of the sun on the N-S axis.",
  Stability:
    "Wisogis will optimize the energy mix to achieve a well balanced and steady electricity production profile",
  LCOE: "Wisogis will select the technology which shows the best Levelized Cost of Electricity.",
  Inbetween:
    "The mix selected will be a trade-off between LCOE and Stability, establish by linear regression.",

  // Advanced LCOE
  "Advanced LCOE":
    "To calculate a LCOE as project specific as possible, Wisogis allows you to fine-tune your financial hypothesis. You can also chose to use the regional-dependant default values. The values provided are those of typical utility-scale installation of above 10MW nominal power.",
  "Grid Connection":
    "Grid connection costs according to the distance to the nearest electrical infrastructure. By default, Wisogis will consider typical Grid Connection costs for the region considered. You can also use custom values by entering your own. If so, the same value will be applied on all your sites. Source: OpenStreetMap 2021",
  "Site grading of the PV plant":
    "Civil work costs to level the PV plant plateform (does note apply on wind installation). By default, Wisogis will consider typicalSite Grading costs for the region considered. You can also use custom values by entering your own. If so, the same value will be applied on all your sites. Source: NASADEM 2021",
  CAPEX:
    "Capital Expenditure in $/kW. By default, Wisogis will consider typical CAPEX values for the region considered. You can also use custom values by entering your own CAPEX. If so, the same value will be applied on all your sites.",
  OPEX: "Operational Expenditure in $/kW/year. By default, Wisogis will consider typical OPEX values for the region considered. You can also use custom values by entering your own OPEX. If so, the same value will be applied on all your sites.",
  WACC: "Weighted Average Cost of Capital in %. Value should be between 0 and 100.",
  Lifetime: "Lifetime of your installation in years.",

  // Exclusion Areas
  "Exclusion Area":
    "Selection of the areas not suitable for your project that should be excluded of the analysis.",
  "Permanent water":
    "Permanent water bodies and Open sea as defined by the Land Cover Classification System. Spatial resolution: down to 100m. Source: Copernicus Global Land Service 2021",
  "Protected areas":
    "The Protected area dataset marks boundaries of protected areas including national parks, wilderness, marine protection areas, heritage sites and other, similar areas. Source: OpenStreetMap 2021",
  "Urban or built up":
    "Land covered by buildings and other manmade structures. Spatial resolution: down to 100m. Source: Copernicus Global Land Service 2021",
  Forest:
    "Any type of forest as defined by the Land Cover Classification System. Spatial resolution: down to 100m. Source: Copernicus Global Land Service 2021",
  "Maximum local slope":
    "Any area with a local terrain slope above the threshold defined by the User (15% by default). Spatial resolution: down to 60m. Source: NASADEM 2021",
  Airports:
    "Airports, aerodromes, airfields, and landing strips whether international, private, military or otherwise with a 10km buffer. This criteria is particularly relevant for wind project. Source: OpenStreetMap 2021",
  "Herbaceous wetland":
    "Lands with a permanent mixture of water and herbaceous or woody vegetation. The vegetation can be present in either salt, brackish, or fresh water. Source: Copernicus Global Land Service 2021",

  // Simulation results pop-up (when clicking on a location)
  Mix: "Optimised solar and wind mix ",
  Slope: "Slope of the terrain",
  "Capacity Factor":
    "The capacity factor is the ratio of the net electricity generated to the energy that could have been generated at continuous full-power operation during the same period.",
};

const StyledTitle = styled.div`
  font-size: 0.9rem;
`;

const HelpText = ({ element, children }) => (
  <Tooltip
    enterDelay={500}
    enterNextDelay={500}
    arrow
    title={<StyledTitle>{tooltips[element]}</StyledTitle>}
  >
    {children}
  </Tooltip>
);

export default HelpText;
