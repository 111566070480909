import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { toggleSidePanel } from "kepler.gl/actions";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import "./styles.scss";
import History from "../bottom_right_menu/History";
import tractebelGrey from "../../assets/tractebel-grey.jpg";
import { PDF_URL } from "../../constants";
import {
  SummaryHelpModal,
  PointHelpModal,
  LocationsHelpModal,
} from "../HelpModals";

const Row = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    margin: "1px",
    color: "#000000",
    width: "100%",
  },
  radio: {
    marginLeft: "20px",
  },
  radioLabel: {
    fontSize: "12px",
  },
  title: {
    fontSize: "18px",
    color: "#000000",
    fontWeight: 300,
    fontFamily: "Lato",
  },
}));

const Panel = ({ title, data }) => (
  <div style={{ display: "flex", flexDireciton: "column" }}>
    <Typography>{title}</Typography>
    {Object.keys(data)
      .filter((d) => !!data[d])
      .map((key) => (
        <div key={key}>
          {typeof data[key] === "object" ? (
            <Panel title={key} data={data[key]} />
          ) : (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
              }}
            >
              <Typography>
                {key.charAt(0).toUpperCase() +
                  key.slice(1).split("_").join(" ")}
              </Typography>
              <Typography>{data[key]}</Typography>
            </div>
          )}
        </div>
      ))}
  </div>
);

const ZoneSelection = (props) => {
  let innerText = "";
  if (props.uiStateReducer.is_locations) {
    innerText = "LOCATIONS";
  } else if (props.formReducer.zoneSelectionData.country) {
    innerText = "COUNTRY";
  } else if (props.formReducer.zoneSelectionData.zone) {
    innerText = "ZONE";
  } else if (props.keplerReducer?.map?.visState?.datasets?.heatmap) {
    innerText = "LOADED";
  } else if (props.loadingReducer.isApiLoadingHeatmap) {
    innerText = "LOADING...";
  } else {
    return <Typography color="error">MISSING</Typography>;
  }

  return <Typography color="primary">{innerText}</Typography>;
};

const AdvancedLCOE = ({ advancedLCOEFormData, isWind, isSolar }) => (
  <>
    <Typography variant="subtitle2" style={{ fontSize: "0.99rem" }}>
      Advanced LCOE
    </Typography>

    <Row>
      <Typography>&bull; Criteria</Typography>
      <div>
        {advancedLCOEFormData.grid_cost_per_km.checked &&
        advancedLCOEFormData.grid_cost_per_km.value ? (
          <Typography color="primary" align="right">
            Grid:{" "}
            {advancedLCOEFormData.grid_connection_type === "substation"
              ? "Substation"
              : "HV Line"}
            : <div>{advancedLCOEFormData.grid_cost_per_km.value}$/kW/km</div>
          </Typography>
        ) : null}
        {advancedLCOEFormData.slope_cost_per_percent.checked &&
        advancedLCOEFormData.slope_cost_per_percent.value ? (
          <Typography color="primary" align="right">
            Slope:{" "}
            <div>
              {advancedLCOEFormData.slope_cost_per_percent.value}$/kW/%slope
            </div>
          </Typography>
        ) : null}
      </div>
    </Row>

    <br />

    <Row>
      <Typography>&bull; CAPEX</Typography>
      <div>
        {isWind ? (
          <Typography color="primary" align="right">
            Wind:{" "}
            {advancedLCOEFormData.wind_capex
              ? `${advancedLCOEFormData.wind_capex} $/kW`
              : "DEFAULT"}
          </Typography>
        ) : null}

        {isSolar ? (
          <Typography color="primary" align="right">
            Solar:{" "}
            {advancedLCOEFormData.solar_capex
              ? `${advancedLCOEFormData.solar_capex} $/kW`
              : "DEFAULT"}
          </Typography>
        ) : null}
      </div>
    </Row>

    <br />

    <Row>
      <Typography>&bull; OPEX</Typography>
      <div>
        {isWind ? (
          <Typography color="primary" align="right">
            Wind:{" "}
            {advancedLCOEFormData.wind_opex
              ? `${advancedLCOEFormData.wind_opex} $/kW/yr`
              : "DEFAULT"}
          </Typography>
        ) : null}

        {isSolar ? (
          <Typography color="primary" align="right">
            Solar:{" "}
            {advancedLCOEFormData.solar_opex
              ? `${advancedLCOEFormData.solar_opex} $/kW/yr`
              : "DEFAULT"}
          </Typography>
        ) : null}
      </div>
    </Row>

    <br />

    <Typography>&bull; Others</Typography>

    {advancedLCOEFormData.lifetime ? (
      <Row>
        <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&bull; Lifetime:</Typography>
        <Typography color="primary">
          {advancedLCOEFormData.lifetime}yrs
        </Typography>
      </Row>
    ) : null}

    <Row>
      <Typography>&nbsp;&nbsp;&nbsp;&nbsp;&bull; Wacc:</Typography>
      <Typography color="primary">
        {advancedLCOEFormData.wacc
          ? `${advancedLCOEFormData.wacc} %`
          : "DEFAULT"}
      </Typography>
    </Row>
  </>
);

export const SummaryPanelComp = (props) => {
  const [isHistoryVisible, setHistoryVisible] = React.useState(false);
  const [isShowStep3, showStep3] = React.useState(false);
  const classes = useStyles();

  const isWind = props.formReducer.renewableTechFormData.wind;
  const isSolar = props.formReducer.renewableTechFormData.solar;

  const renewableSelected = isWind || isSolar;

  return (
    <>
      {isShowStep3 ? <PointHelpModal /> : null}
      {props.uiStateReducer.is_locations ? (
        <LocationsHelpModal />
      ) : (
        <SummaryHelpModal />
      )}
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        {isHistoryVisible && (
          <History
            dispatch={props.dispatch}
            setHistoryVisible={(e) => {
              setHistoryVisible(e);
            }}
            toggleToSidePanel={(e) => {
              setHistoryVisible(e);
              props.dispatch(toggleSidePanel("renewable-sizing"));
            }}
          />
        )}

        <div className={classes.root} style={{ flex: 1 }}>
          <Row>
            <Typography variant="subtitle2" style={{ fontSize: "0.99rem" }}>
              Zone Selection
            </Typography>

            <ZoneSelection {...props} />
          </Row>
          <br />
          <Row>
            <Typography variant="subtitle2" style={{ fontSize: "0.99rem" }}>
              Renewable Technology
            </Typography>

            <div>
              {renewableSelected ? (
                <div>
                  <div>
                    <Typography color="primary" align="right">
                      {isWind && "Wind"}
                      {isWind && isSolar ? " & " : " "}
                      {isSolar && "Solar"}
                      {isSolar ? (
                        props.formReducer.renewableTechFormData.pv_tracking ===
                        "fixed" ? (
                          <div> (Fixed tilt)</div>
                        ) : (
                          <div> (Single axis tracking)</div>
                        )
                      ) : (
                        " "
                      )}
                    </Typography>
                  </div>
                </div>
              ) : (
                <Typography color="error">MISSING</Typography>
              )}
            </div>
          </Row>
          <br />
          <Row>
            <Typography>&bull; Renewable mix opti:</Typography>
            <div>
              <Typography color="primary">
                {isSolar
                  ? isWind
                    ? `LCOE: ${(
                        100 *
                        props.formReducer.renewableTechFormData.combined_slider
                      ).toFixed()}%`
                    : "SOLAR"
                  : "WIND"}
              </Typography>
              <Typography color="primary" align="right">
                {isSolar
                  ? isWind
                    ? `Stability: ${(
                        100 -
                        100 *
                          props.formReducer.renewableTechFormData
                            .combined_slider
                      ).toFixed()}%`
                    : "SOLAR"
                  : "WIND"}
              </Typography>
            </div>
          </Row>
          <AdvancedLCOE
            advancedLCOEFormData={props.formReducer.advancedLCOEFormData}
            isWind={isWind}
            isSolar={isSolar}
          />
          <br />
          <Row>
            <Typography variant="subtitle2" style={{ fontSize: "0.99rem" }}>
              Exclusion Areas
            </Typography>

            <div>
              {props.formReducer.exclusionAreasFormData.water ? (
                <div>
                  <Typography color="primary" align="right">
                    Permanent water
                  </Typography>
                </div>
              ) : null}
              {props.formReducer.exclusionAreasFormData.wetland ? (
                <div>
                  <Typography color="primary" align="right">
                    Herbaceous wetlands
                  </Typography>
                </div>
              ) : null}
              {props.formReducer.exclusionAreasFormData.is_protected_area ? (
                <div>
                  <Typography color="primary" align="right">
                    Protected areas
                  </Typography>
                </div>
              ) : null}
              {props.formReducer.exclusionAreasFormData.is_urban ? (
                <div>
                  <Typography color="primary" align="right">
                    Urban or built up
                  </Typography>
                </div>
              ) : null}
              {props.formReducer.exclusionAreasFormData.is_forest ? (
                <div>
                  <Typography color="primary" align="right">
                    Forests
                  </Typography>
                </div>
              ) : null}
              {props.formReducer.exclusionAreasFormData
                .ui_show_complex_terrain ? (
                <div>
                  <Typography color="primary" align="right">
                    Maximum local slope
                  </Typography>
                </div>
              ) : null}
              {props.formReducer.exclusionAreasFormData.is_airports ? (
                <div>
                  <Typography color="primary" align="right">
                    Airports
                  </Typography>
                </div>
              ) : null}
            </div>
          </Row>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            onClick={() => props.dispatch(toggleSidePanel("exclusion"))}
            className="previous-button"
          >
            Previous
          </div>
          <div
            className="run-button"
            onClick={
              !props.uiStateReducer.is_locations
                ? () => {
                    if (
                      props.keplerReducer.map.uiState.mapControls.mapDraw
                        .active === true
                    ) {
                      props.dispatch({
                        type: "@@kepler.gl/TOGGLE_MAP_CONTROL",
                        payload: {
                          panelId: "mapDraw",
                          index: 0,
                        },
                      });
                    }
                    if (props.keplerReducer.map.visState.layers.length)
                      showStep3(true);
                    else setHistoryVisible(true);
                  }
                : () => window.open(PDF_URL, "_blank")
            }
          >
            Run
          </div>
        </div>
        <div style={{ padding: "14px 30px", textAlign: "center" }}>
          <img src={tractebelGrey} style={{ maxWidth: "50%" }} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingReducer: state.loadingReducer,
    formReducer: state.formReducer,
    uiStateReducer: state.uiStateReducer,
    appReducer: state.appReducer,
    keplerReducer: state.keplerGl,
  };
};
const dispatchToProps = (dispatch) => ({ dispatch });

export const SummaryPanel = connect(
  mapStateToProps,
  dispatchToProps
)(SummaryPanelComp);
