const lebanon = {
  LCOE: 385.74,
  wind_capacity_factor: 0.05771330809062106,
  solar_capacity_factor: 0.21883572219620916,
  average_capacity_factor: 0.10631330809062106,
  mix: 0.9835,
  wind_monthly_capacity_factor: [
    0.2383994970805137, 0.18154168622541283, 0.1204273218027411,
    0.09726646098645299, 0.07188771250676099, 0.05791982249422328,
    0.13508314946988317, 0.07628440216370444, 0.030724802834680518,
    0.031528965400597936, 0.0626982591303529, 0.17357959271753548,
  ],
  solar_monthly_capacity_factor: [
    0.16514420102131622, 0.18856213023134658, 0.2074513959716763,
    0.2153189219529106, 0.23033922717887645, 0.21193348074792323,
    0.2152877106715248, 0.21835999262972317, 0.21140150081482914,
    0.1856738407397384, 0.1813842588302035, 0.14849860226335862,
  ],
  wind_hourly_capacity_factor: [
    0.10451928077509567, 0.101602410395345, 0.09856192945279302,
    0.0958540464008082, 0.09421680080458943, 0.09334166094565417,
    0.09547919019347649, 0.10126564170801355, 0.10659521149650189,
    0.10899816159325866, 0.1059269497848257, 0.10634007278933226,
    0.10781723891466967, 0.10918087818041874, 0.10986204891029847,
    0.11066432283572472, 0.1102307787285269, 0.11240216765818976,
    0.11547113883067363, 0.11589489366906604, 0.11677072997474222,
    0.11545412753247572, 0.10852315612532676, 0.10654655647509916,
  ],
  solar_hourly_capacity_factor: [
    0, 0, 0, -0.0000674407232876713, 0.009198884616988047, 0.08574767368503958,
    0.2473864366928107, 0.41218486334357124, 0.5434006893423067,
    0.6309942377571036, 0.6730407748046842, 0.6623323892716017,
    0.5958340637702202, 0.48450942857946966, 0.3272567094267381,
    0.08457402814576372, 0.002464593996009384, 0, 0, 0, 0, 0, 0, 0,
  ],
  slope: 69.5018105523749,
  country: "Lebanon",
  temperature: 21.143771024956948,
  wind_speed: 4.432384729385376,
  ghi: 1907.8550863265991,
  wind_capex: 1515,
  solar_capex: 1008.281,
  wind_opex: 30.3,
  solar_opex: 10.08,
  electricity_tarif: "13.0",
  electricity_ranking: "127",
  grid_connection_time: "89",
  layers: {
    water: false,
  },
};

export default lebanon;
