import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { addDataToMap, toggleMapControl } from "kepler.gl/actions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import { heatmapConfig } from "../../map-configs";
import { PointHelpModal } from "../HelpModals";

import loadIreland, { setMenuValuesIreland } from "../../utils/loadIreland";
import loadLebanon1, { setMenuValuesLebanon1 } from "../../utils/loadLebanon1";
import loadLebanon2, { setMenuValuesLebanon2 } from "../../utils/loadLebanon2";
import loadChile, { setMenuValuesChile } from "../../utils/loadChile";

import {
  showSuccessSnackbar,
  showInfoSnackbar,
} from "../../actions/snackbarActions";

const IRELAND = "ireland";
const LEBANON_1 = "lebanon_1";
const LEBANON_2 = "lebanon_2";
const CHILE = "chile";

const getDataSets = async (scenario = IRELAND, dispatch) => {
  let datasets = [];
  switch (scenario) {
    case IRELAND:
      setMenuValuesIreland(dispatch);
      datasets = loadIreland();
      break;
    case LEBANON_1:
      setMenuValuesLebanon1(dispatch);
      datasets = loadLebanon1();
      break;
    case LEBANON_2:
      setMenuValuesLebanon2(dispatch);
      datasets = loadLebanon2();
      break;
    case CHILE:
      setMenuValuesChile(dispatch);
      datasets = loadChile();
      break;
    default:
      return;
  }

  let heatmapIndex;
  datasets.forEach((dataSet, index) => {
    if (dataSet.info && dataSet.info.id === "heatmap") {
      heatmapIndex = index;
    }
  });
  datasets[heatmapIndex].data.fields.forEach((field) => {
    if (field.name === "LCOE") {
      field.name = "LCOE [$/MWh]";
    }
  });

  dispatch(
    addDataToMap({
      datasets,
      options: {
        readOnly: false,
        keepExistingConfig: false,
        centerMap: true,
        autoCreateLayers: true,
      },
      config: heatmapConfig,
    })
  );
  dispatch(showSuccessSnackbar("Loaded all layers successfully"));
  dispatch({ type: "REORDER_LAYERS" });
  dispatch(toggleMapControl("mapLegend"));
};

const HistoryRow = ({ name, scenario, setHistoryVisible, dispatch }) => {
  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell component="th" scope="row">
          {name}
        </TableCell>
        <TableCell align="center">{new Date().toLocaleDateString()}</TableCell>
        <TableCell align="center">Heatmap</TableCell>
        <TableCell
          align="right"
          onClick={() => {
            dispatch(showInfoSnackbar("Retrieving past result..."));
            dispatch({
              type: "SET_HELP_MODAL",
              payload: "POINT",
            });
            getDataSets(scenario, dispatch);
            setHistoryVisible(false);
          }}
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon icon={faArrowDown} height="16px" />
        </TableCell>
      </TableRow>
    </>
  );
};

const HistoryTable = ({ toggleToSidePanel, dispatch }) => (
  <TableContainer component={Paper} style={{ height: 256 }}>
    <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell align="center">Date</TableCell>
          <TableCell align="center">Type</TableCell>
          <TableCell align="right">Load</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <HistoryRow
          name="Ireland (wind)"
          date="2022-05-05T06:45:21.101280"
          scenario={IRELAND}
          setHistoryVisible={toggleToSidePanel}
          dispatch={dispatch}
        />
        <HistoryRow
          name="Lebanon (solar + wind, stability) "
          date="2022-05-05T06:37:45.551617"
          scenario={LEBANON_1}
          setHistoryVisible={toggleToSidePanel}
          dispatch={dispatch}
        />
        <HistoryRow
          name="Lebanon (solar + wind, LCOE)"
          date="2022-05-05T06:37:45.551617"
          scenario={LEBANON_2}
          setHistoryVisible={toggleToSidePanel}
          dispatch={dispatch}
        />
        <HistoryRow
          name="Chili (solar + wind)"
          date="2022-05-05T06:37:45.551617"
          scenario={CHILE}
          setHistoryVisible={toggleToSidePanel}
          dispatch={dispatch}
        />
      </TableBody>
    </Table>
  </TableContainer>
);

const History = (props) => {
  const {
    dispatch,
    setHistoryVisible,
    toggleToSidePanel,
    uiStateReducer,
    noHistory = false,
  } = props;

  return (
    <>
      {/* <ParametersScreen
        isHelpOpen={uiStateReducer?.modal === "POINT"}
        dispatch={dispatch}
      /> */}
      {/* <PointHelpModal
        isHelpOpen={uiStateReducer?.modal === "LOCATION"}
        dispatch={dispatch}
      /> */}
      {noHistory ? null : (
        <div
          style={{
            backgroundColor: "#f7f7f7",
            position: "fixed",
            zIndex: 100,
            top: "20vh",
            margin: "auto",
            left: 0,
            right: 0,
            width: 600,
          }}
        >
          <div
            style={{
              position: "absolute",
              right: -10,
              borderRadius: 24,
              border: "2px solid rgb(128, 128, 128)",
              top: -10,
              background: "white",
              width: 24,
              textAlign: "center",
              zIndex: 2000,
              cursor: "pointer",
            }}
            onClick={() => setHistoryVisible(false)}
          >
            X
          </div>
          <HistoryTable
            toggleToSidePanel={toggleToSidePanel}
            dispatch={dispatch}
          />
        </div>
      )}
    </>
  );
};

export default History;
