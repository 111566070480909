import {
  injectComponents,
  CustomPanelsFactory,
  PanelHeaderFactory,
  CollapseButtonFactory,
} from "kepler.gl/components";
import { ScaleControl } from "mapbox-gl";
import AutoSizer from "react-virtualized/dist/commonjs/AutoSizer";
import CustomPanels from "./CustomPanels";
import CustomCollapseButton from "./CustomCollapseButton";
import { CustomHeader } from "./CustomHeader";
import { themeLight } from "../theme";

export const KeplerGl = injectComponents([
  [CustomPanelsFactory, CustomPanels],
  [PanelHeaderFactory, CustomHeader],
  [CollapseButtonFactory, CustomCollapseButton],
]);

const getMapboxRef = (mapbox, index) => {
  if (mapbox) {
    const map = mapbox.getMap();
    const scale = new ScaleControl({
      maxWidth: 160,
    });
    map.addControl(scale, "bottom-right");
  }
};

// render KeplerGl, it will render your custom components from factories
export const MapContainer = () => (
  <>
    <AutoSizer>
      {({ height, width }) => (
        <KeplerGl
          id="map"
          width={width}
          mapboxApiAccessToken="pk.eyJ1IjoiaHViZXJ0byIsImEiOiJja2tjamNnY2IwZmh3MnBxcmhqcW43dnE5In0.fIEXMsXpZRcKbQ1A_g7ZgQ"
          height={height}
          appName={"Wisogis"}
          version={"Alpha 0.3"}
          getState={(state) => state.keplerGl}
          theme={themeLight}
          getMapboxRef={getMapboxRef}
          style={{ overflow: "hidden" }}
        />
      )}
    </AutoSizer>
  </>
);
