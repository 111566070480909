import { useState } from "react";
import { connect } from "react-redux";
import { layerConfigChange, toggleMapControl } from "kepler.gl/actions";
import visibleIcon from "../../../assets/visibility.svg";
import notVisibleIcon from "../../../assets/visibility_off.svg";
import ReactTooltip from "react-tooltip";
import "./style.scss";

const toggleLayer = (layerData, dispatch, visibility) => {
  dispatch(layerConfigChange(layerData, { isVisible: !visibility }));
};

function MapLayersSelector(props) {
  // State used to hide tooltip when submenu is open
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  let layerForList = props.map?.visState?.layers || [];

  const toggleLayerSelection = () => {
    const layerOptions = document.querySelector(".layer-options");
    const maplayerOptions = document.querySelector(".maplayer-options");
    const styleOptions = document.querySelector(".style-options");

    layerOptions.style.visibility = "hidden";
    styleOptions.style.visibility = "hidden";

    const legendOptions = document.querySelector(".close-map-control-item");
    if (legendOptions) props.dispatch(toggleMapControl("mapLegend"));

    if (maplayerOptions.style.visibility === "hidden") {
      maplayerOptions.style.visibility = "visible";
      setIsMenuVisible(true);
    } else {
      maplayerOptions.style.visibility = "hidden";
      setIsMenuVisible(false);
    }
  };

  return (
    <div
      data-tip
      data-for="custom-maplayer-selector-tip"
      className="custom-maplayer-selector"
    >
      <div
        onClick={() => (layerForList.length ? toggleLayerSelection() : null)}
        className="svg-container"
      >
        <svg
          className="multi-path"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path
            id="path-fill"
            fill="#6A7485"
            d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z"
          />
        </svg>
      </div>
      <ReactTooltip
        disable={isMenuVisible}
        id="custom-maplayer-selector-tip"
        type="info"
        effect="solid"
        className="custom-tooltip-class"
        arrowColor="#1869b5"
      >
        <span>Layers</span>
      </ReactTooltip>
      <div style={{ visibility: "hidden" }} className="maplayer-options">
        {layerForList.length > 0 ? (
          layerForList.map((layer) => (
            <div
              key={layer.id}
              onClick={() =>
                toggleLayer(layer, props.dispatch, layer.config.isVisible)
              }
              className="layer-option"
            >
              <img
                src={
                  layer.config.isVisible === true ? visibleIcon : notVisibleIcon
                }
                alt="visible"
              ></img>
              <div>{layer.config.label}</div>
            </div>
          ))
        ) : (
          <div style={{ fontSize: "0.8em" }}>Please load a map first.</div>
        )}
      </div>
    </div>
  );
}

const dispatchToProps = (dispatch) => ({ dispatch });
const mapStateToProps = (state) => state.keplerGl;

export default connect(mapStateToProps, dispatchToProps)(MapLayersSelector);
