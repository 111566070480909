import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { faLeaf } from "@fortawesome/free-solid-svg-icons";
import { faFunnelDollar } from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import {
  ZonePanel,
  AdvancedLCOEPanel,
  ExclusionAreasPanel,
  RenewableSizingPanel,
  SummaryPanel,
} from "./panels/index";
import HelpText from "./HelpText";

const StyledIcon = styled(FontAwesomeIcon)`
  color: #6a7485;
  font-size: 22px;
  padding: 2px 0;
  margin: 2px 0;
  &:hover {
    color: #0b74c0;
  }
`;

function CustomPanelsExported() {
  const CustomPanels = (props) => {
    if (props.activeSidePanel === "zone") {
      return <ZonePanel />;
    } else if (props.activeSidePanel === "renewable-sizing") {
      return <RenewableSizingPanel />;
    } else if (props.activeSidePanel === "advanced-lcoe") {
      return <AdvancedLCOEPanel />;
    } else if (props.activeSidePanel === "exclusion") {
      return <ExclusionAreasPanel />;
    } else if (props.activeSidePanel === "summary") {
      return <SummaryPanel />;
    }

    return null;
  };

  CustomPanels.defaultProps = {
    panels: [
      {
        id: "zone",
        label: "Zone Selection",
        iconComponent: () => <StyledIcon icon={faMapMarkedAlt} />,
      },
      {
        id: "renewable-sizing",
        label: "Renewable Technology",
        iconComponent: () => <StyledIcon icon={faLeaf} />,
      },
      {
        id: "advanced-lcoe",
        label: "Advanced LCOE",
        iconComponent: () => <StyledIcon icon={faFunnelDollar} />,
      },
      {
        id: "exclusion",
        label: "Exclusion Areas",
        iconComponent: () => <StyledIcon icon={faLayerGroup} />,
      },

      {
        id: "summary",
        label: "Summary",
        iconComponent: () => <StyledIcon icon={faClipboard} />,
      },
    ],
    getProps: (props) => ({
      layers: props.layers,
    }),
  };

  return CustomPanels;
}

export default CustomPanelsExported;
