export const CONTACT_US_EMAIL = "support.wisogis@tractebel.engie.com ";
export const PRIVACY_STATEMENT_LINK =
  "https://digital.tractebel-engie.com/wisogis-privacy-statement/";
export const DOCUMENTATION_LINK = "https://wisogis.tractebel.com/documentation";
export const TERMS_OF_USE =
  "https://digital.tractebel-engie.com/additional-terms-of-use-for-wisogis/";

export const SNACKBAR_SUCCESS = "SNACKBAR_SUCCESS";
export const SNACKBAR_INFO = "SNACKBAR_INFO";
export const SNACKBAR_ERROR = "SNACKBAR_ERROR";
export const SNACKBAR_CLEAR = "SNACKBAR_CLEAR";

export const SET_API_LOADING_HEATMAP = "SET_API_LOADING_HEATMAP";
export const SET_API_LOADING_LOCATION_DATA = "SET_API_LOADING_LOCATION_DATA";

export const EXPORT_GEOJSON_LAYERS = "EXPORT_GEOJSON_LAYERS";
export const SAVE_S3_FILE_ID = "SAVE_S3_FILE_ID";

export const PDF_URL = "/Wisogis report.pdf";
