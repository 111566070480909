import { createGlobalStyle } from "styled-components";

/*
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap");
@import "~material-design-icons/iconfont/material-icons.css";
$icon-font-path: "~@engie-group/fluid-design-system/src/components/icon/fonts/";
@import "~@engie-group/fluid-design-system/src/fluid-design-system.scss";
*/

const GlobalStyles = createGlobalStyle`
body,
html,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Lato, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.map-popover__top {
   height: 0;
   visibility: hidden;
 }

.App {
    width: 100%;
    height: 100%;
    .side-side-panel__header__bottom {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      min-height: 37px;
      padding: 0 !important;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .App
    div
    div
    div
    .side-panel--container
    div
    div
    .side-side-panel__header__bottom
    [data-for="layer-nav"] {
    display: none;
  }
  .App
    div
    div
    div
    .side-panel--container
    div
    div
    .side-side-panel__header__bottom
    [data-for="filter-nav"] {
    display: none;
  }
  
  .App
    div
    div
    div
    .side-panel--container
    div
    div
    .side-side-panel__header__bottom
    [data-for="interaction-nav"] {
    display: none;
  }
  
  .App
    div
    div
    div
    .side-panel--container
    div
    div
    .side-side-panel__header__bottom
    [data-for="map-nav"] {
    display: none;
  }
  
  a.mapboxgl-ctrl-logo {
    width: 90px !important;
  }


  .side-panel--container {
    max-height: 70%;
    min-height: 730px;
  }
  
  .side-panel__tab {
    margin-top: 8px;
  }
  
  .side-bar__close {
    top: 96px !important;
  }
  
  .side-panel__content__title {
    font-family: "Lato";
    color: #212121 !important;
    font-weight: 700 !important;
    letter-spacing: 0px !important;
    margin-bottom: 8px !important;
  }
  
  .header-container {
    font-family: "Lato";
    padding: 16px;
    display: flex;
  
    .export-container {
      display: grid;
      align-items: center;
      justify-items: end;
      img {
        cursor: pointer;
        width: 20px;
        margin-right: 5px;
      }
    }
    .wisogis-logo {
      position: relative;
      align-self: center;
      height: 50px;
    }
    .tractebel-logo {
      height: 30px;
      margin: auto;
    }
    padding-bottom: 10px;
  }
  
  .mapboxgl-ctrl-scale {
    background-color: #ffffff42 !important;
    border: 1px solid #24242484 !important;
    border-top: 0 !important;
  }
  
  .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
    margin: 0 60px 50px 0 !important;
    float: right;
  }
  
  .map-legend {
    background-color: #f7f7f7;
  }
  .button-wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  .switch-container {
    display: grid;
    color: #212121;
    font-weight: 400;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 12px;
    .heatmap-switch {
      justify-self: right;
      align-self: center;
    }
    .MuiSwitch-root {
      align-self: center;
      justify-self: center;
      .MuiSwitch-track {
        background-color: #0b74c0;
      }
      .MuiSwitch-colorSecondary.Mui-checked,
      .MuiSwitch-switchBase {
        color: #0b74c0;
      }
    }
    .locations-switch {
      justify-self: left;
      align-self: center;
    }
  }
  
  .zone-selector {
    height: 100%;
    margin-top: 12px;
    .active {
      border: solid 2px rgba(1, 140, 210, 0.75);
      border-radius: 5px;
    }
  
    .country-image:hover,
    .area-image:hover {
      cursor: pointer;
      // transform: scale(1.05);
      // transition: all 0.3s;
    }
    .country-title,
    .zone-title {
      font-family: "Lato";
      font-size: 16px;
    }
  
    .button-wrapper {
      padding-bottom: 20px;
      grid-column: 1 / 3;
      align-self: end;
    }
  }
  
  .previous-button {
    -webkit-box-align: center;
    -webkit-box-pack: center;
    align-items: center;
    background-color: white;
    border-radius: 0px;
    border: 1px solid #009de9;
    box-sizing: border-box;
    color: #009de9;
    cursor: pointer;
    display: grid;
    font-family: "Lato";
    font-size: 14px;
    font-weight: 400;
    justify-content: center;
    letter-spacing: 0.3px;
    line-height: 14px;
    margin: 12px 0;
    opacity: 1;
    outline: 0px;
    padding: 12px 24px;
    pointer-events: all;
    text-align: center;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
  }
  
  .previous-button:hover {
    color: white;
    background-color: #009de9;
  }
  
  .run-button {
    box-sizing: border-box;
    -webkit-box-align: center;
    align-items: center;
    background-color: #009de9;
    border-radius: 0px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: grid;
    font-size: 14px;
    font-weight: 400;
    font-family: "Lato";
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: 0.3px;
    line-height: 14px;
    outline: 0px;
    text-align: center;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    width: 78px;
    height: 44px;
    opacity: 1;
    pointer-events: all;
    padding: 12px 24px;
    border: 0px;
    margin: 12px 0;
  }
  
  .run-button:hover {
    background-color: #0074ac;
  }
  
  .renewable-container {
    padding-bottom: 20px;
    height: 100%;
    display: grid;
    grid-template-rows: auto auto;
    .renewable-tech-panel {
      [role="radiogroup"] .MuiTypography-root {
        font-size: 16px;
      }
      .MuiFormControlLabel-root {
        height: 25px;
      }
    }
    .button-wrapper {
      padding-bottom: 20px;
      align-self: end;
    }
  }
  .renewable-sizing-container {
    height: 100%;
    display: grid;
    grid-template-rows: auto auto;
    .renewable-sizing-panel {
      display: grid;
      grid-template-rows: 30px auto;
      div[role="radiogroup"] > .MuiFormControlLabel-root {
        height: 25px;
      }
      .slider-container {
        display: grid;
        justify-self: center;
        width: 100%;
        margin: 10px auto;
        .MuiSlider-markLabel {
          color: rgba(0, 0, 0, 0.87);
        }
        [data-index="1"] {
          transform: translateX(-100%);
        }
        [data-index="0"] {
          transform: translateX(0%);
        }
        .tooltip-slider {
          border: solid 1px white;
        }
      }
    }
    .button-wrapper {
      padding-bottom: 20px;
      align-self: end;
    }
  }
  
  .exclusion-panel {
    display: grid;
    height: 100%;
    grid-template-rows: auto auto;
    padding-bottom: 20px;
  
    margin-top: -12px;
    .MuiFormControlLabel-root {
      margin-bottom: 0px !important;
      .MuiCheckbox-root {
        padding: 9px 2px 9px 0px;
      }
      .custom-13pxlabel + .MuiFormControlLabel-label {
        font-size: 13px !important;
      }
    }
    .check-and-text-div {
      display: grid;
      grid-template-columns: 80% 20%;
  
      .MuiFormControl-root {
        position: relative;
        bottom: 4px;
        .MuiFormLabel-root {
          font-size: 10px;
          font-weight: bold;
        }
        .MuiFormLabel-root.Mui-focused {
          font-size: 9px;
          font-weight: bold;
          color: #009de9;
        }
        .MuiInputBase-root {
          width: 80px;
          margin-top: 5px;
          &:after {
            border-bottom: 2px solid #009de9 !important;
          }
        }
      }
    }
    .button-wrapper {
      padding-bottom: 20px;
      align-self: end;
    }
  
    /* 
      special style to make the last two options not too long
    */
  }
  
  .advanced-lcoe-panel-container {
    display: grid;
    height: 100%;
    grid-template-rows: auto auto;
    .advanced-lcoe-panel {
      display: grid;
      .icon {
        width: 20px;
      }
      #capex-label {
        margin-bottom: 5px;
        font-family: "Lato";
        font-size: 12px;
        p {
          font-family: "Lato";
          font-size: 12px;
          margin-bottom: 0px;
        }
        p:nth-child(1) {
          font-size: 14px;
        }
      }
      #opex-label {
        margin: 5px 0;
        font-family: "Lato";
        font-size: 12px;
      }
      #capex-div,
      #opex-div {
        display: grid;
        grid-template-columns: 30px auto 30px auto;
        align-items: center;
        .MuiInputBase-root {
          width: 80px;
          &:after {
            border-bottom: 2px solid #009de9 !important;
          }
        }
      }
      #opex-div {
        margin-bottom: 5px;
      }
  
      #wacc-div,
      #lifetime-div {
        width: 100px;
        .MuiFormControl-root {
          .MuiInputBase-root {
            width: 100px;
            &:after {
              border-bottom: 2px solid #009de9 !important;
            }
          }
        }
      }
  
      .criteria-label {
        font-family: "Lato";
        font-size: 14px;
      }
  
      #criteria-div {
        div[role="radiogroup"] > .MuiFormControlLabel-root {
          height: 25px;
        }
        .MuiInputBase-root {
          width: 100px;
  
          &:after {
            border-bottom: 2px solid #009de9 !important;
          }
        }
      }
  
      .run {
        justify-self: center;
      }
    }
    .button-wrapper {
      padding-bottom: 20px;
      align-self: end;
    }
  }
  
  .side-bar {
    box-shadow: none !important;
  }
  
  .export-container {
    :first-child {
      display: none !important;
    }
    min-height: 40px;
  }
  .topmenu-container {
    display: block;
    right: 0;
    bottom: 50px;
    padding: 12px;
    z-index: 10;
    margin-top: 0px;
    position: absolute;
    height: 120px;
    .user-menu,
    .upload-file {
      padding: 4px 0;
      div {
        display: grid;
        box-shadow: 0 6px 12px 0 #00000029;
        height: 32px;
        width: 32px;
        padding: 0;
        border-radius: 0;
        background-color: #f7f7f7;
        color: #6a7485;
        border: 0;
        align-items: center;
        justify-items: center;
        &:hover {
          cursor: pointer;
          svg {
            fill: #2473bd;
          }
        }
      }
    }
  }
  .custom-map-control {
    display: block;
    right: 0;
    padding: 12px;
    z-index: 10;
    margin-top: 0px;
    position: absolute;
    height: 154px;
    top: 44px;
    .custom-style-selector,
    .custom-layer-selector,
    .custom-maplayer-selector {
      padding: 4px 0;
      .svg-container {
        display: grid;
        box-shadow: 0 6px 12px 0 #00000029;
        height: 36px;
        width: 36px;
        padding: 0;
        border-radius: 0;
        background-color: #f7f7f7;
        color: #6a7485;
        border: 0;
        align-items: center;
        justify-items: center;
        &:hover {
          cursor: pointer;
          svg {
            fill: #2473bd;
          }
          .multi-path {
            path {
              fill: #2473bd;
            }
          }
        }
      }
    }
  }
  
  .custom-tooltip-class {
    height: 30px;
    background-color: #1869b5 !important;
    color: #ffffff !important;
    font-size: 10px !important;
    padding: 7px 18px !important;
    font-weight: 400 !important;
  }
  
  .toggle-3d > .map-control-button,
  .show-legend {
    height: 36px !important;
    width: 36px !important;
  }



  .map-control > .show-legend {
    position: absolute;
    top: 188px;
  }

  .show-legend &:first-letter {
    text-transform: uppercase;
  }
  

  .custom-layer-selector {
    .svg-container {
      &:hover {
        svg {
          polygon,
          path,
          rect:not(:nth-child(1)) {
            fill: #2473bd;
          }
        }
      }
    }
    .layer-options {
      position: absolute;
      width: 120px;
      height: 120px;
      right: 55px;
      top: 60px;
      background-color: #f7f7f7;
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  
      .layer-option {
        cursor: pointer;
        display: grid;
        padding: 2px;
        grid-template-columns: 40px auto;
        &:hover {
          background-color: rgb(190, 190, 190);
        }
        img {
          user-select: none;
          align-self: center;
          width: 20px;
          border-radius: 5px;
          margin-left: 5px;
        }
        div {
          user-select: none;
          align-self: center;
          text-align: end;
          margin-right: 5px;
          font-size: 12px;
        }
      }
    }
  }

  .custom-maplayer-selector {
    .svg-container {
      &:hover {
        svg {
          polygon,
          path,
          rect:not(:nth-child(1)) {
            fill: #2473bd;
          }
        }
      }
    }
    .maplayer-options {
      position: absolute;
      width: 120px;
      min-height: 32px;
      right: 55px;
      top: 104px;
      background-color: #f7f7f7;
      display: grid;
      grid-template-rows: auto;
  
      .layer-option {
        cursor: pointer;
        display: grid;
        grid-template-columns: 40px auto;
        &:hover {
          background-color: rgb(190, 190, 190);
        }
        img {
          user-select: none;
          align-self: center;
          width: 20px;
          border-radius: 5px;
          margin-left: 5px;
        }
        div {
          user-select: none;
          align-self: center;
          text-align: end;
          margin-right: 5px;
          font-size: 12px;
        }
      }
    }
  }
  
  .style-options {
    position: absolute;
    width: 120px;
    height: 120px;
    right: 55px;
    top: 16px;
    background-color: #f7f7f7;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
  
    .style-option-wrapper {
      cursor: pointer;
      display: grid;
      grid-template-columns: 40px auto;
      &:hover {
        background-color: rgb(190, 190, 190);
      }
      img {
        user-select: none;
        align-self: center;
        width: 40px;
        border-radius: 5px;
        margin-left: 5px;
      }
      div {
        user-select: none;
        align-self: center;
        text-align: end;
        margin-right: 5px;
        font-size: 12px;
      }
    }
  }
  
`;

export default GlobalStyles;
