const ireland = {
  LCOE: 49.18,
  wind_capacity_factor: 0.38532647739095836,
  average_capacity_factor: 0.47506647739095836,
  mix: 1,
  wind_monthly_capacity_factor: [
    0.5082570709031827, 0.6268468562842302, 0.6225223293889033,
    0.5087750035777826, 0.26372455347183116, 0.3819113163637302,
    0.3334360481613186, 0.4504546372617938, 0.42145054707873725,
    0.5458862712410001, 0.45355283409502334, 0.5943275166133624,
  ],
  wind_hourly_capacity_factor: [
    0.47321491883781613, 0.48416762125406826, 0.4875544674488202,
    0.48919609598352104, 0.4866311733894618, 0.48692689493709007,
    0.483861489927515, 0.4838676209592938, 0.48245081508144616,
    0.47907511915088336, 0.47821499323662076, 0.4792264902083376,
    0.47865567902909206, 0.4728916734252426, 0.4654589436014401,
    0.4636848633506596, 0.46328501039054865, 0.4663648190944017,
    0.46472953287208524, 0.4622029449738901, 0.46392965775377853,
    0.46850052155342875, 0.46839135177674934, 0.46911275914680023,
  ],
  slope: 4.296150158075673,
  country: "Ireland",
  temperature: 10.79980063532156,
  wind_speed: 7.462870197296143,
  ghi: 860.6700146198273,
  wind_capex: 1515,
  solar_capex: 924.263,
  wind_opex: 30.3,
  solar_opex: 19.215,
  electricity_tarif: "19.0",
  electricity_ranking: "47",
  grid_connection_time: "85",
  layers: {
    water: false,
  },
};

export default ireland;
