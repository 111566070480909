import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toggleSidePanel } from "kepler.gl/actions";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import History from "./bottom_right_menu/History";
import { faMap, faGlobe, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { CONTACT_US_EMAIL } from "../constants";

const MyModal = styled.div`
  display: ${(props) => (props.open ? "inherit" : "none")};
`;

const ActionButton = styled.a`
  color: white;
  font-weight: bold;
  text-align: center;
  background-color: #009de9;
  cursor: pointer;
  padding: 12px;
`;

const CloseButton = styled.div`
  position: absolute;
  right: -10px;
  border-radius: 24px;
  border: 2px solid rgb(128, 128, 128);
  top: -10px;
  background: white;
  width: 24px;
  text-align: center;
  z-index: 2000;
  cursor: pointer;
`;

const ModalContainer = styled.div`
  background-color: #ffffff;
  position: fixed;
  z-index: 100;
  top: 20vh;
  margin: auto;
  left: 0;
  right: 0;
  width: 600px;
  ${(props) =>
    props.left &&
    `
  margin: inherit;
  left: ${props.left};
  width: ${props.width || "400px"};
  `}
  ${(props) =>
    props.top &&
    `
  top: ${props.top};
  `}
`;

const WelcomeHelpModal = ({
  isHelpOpen,
  setHelpOpen,
  currentScreen,
  setCurrentScreen,
}) => {
  const modal = document.getElementById("heatmapHelpModal");
  React.useEffect(() => {
    if (modal && currentScreen === "welcome") {
      modal.style.visibility = "hidden";
    }
  }, [modal]);

  return (
    <Modal
      hideBackdrop
      open={isHelpOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer>
        <CloseButton
          onClick={() => {
            modal.style.visibility = "inherit";
            setHelpOpen(false);
            setCurrentScreen(false);
          }}
        >
          X
        </CloseButton>
        <div style={{ padding: 24 }}>
          <Typography variant="h5" align="center">
            WELCOME TO THE WISOGIS DEMO!
          </Typography>

          <div style={{ margin: 12 }}>
            <Typography align="center">
              This demo version will guide you through the different
              functionalities and workflow of the tool for some preselected
              countries, locations and parameters.
            </Typography>
          </div>
          <div style={{ margin: 12 }}>
            <Typography align="center">
              Interested in a license? Check out the different options above
              (Buy a license). Feel free to contact the Wisogis team for further
              questions:{" "}
              <a href="mailto:support.wisogis@tractebel.engie.com">
                support.wisogis@tractebel.engie.com
              </a>
            </Typography>
          </div>
          <div style={{ margin: 12 }}>
            <Typography align="center">
              Note: for optimal user experience, a minimum display resolution of 1980x1200 is recommended.
            </Typography>
          </div>

          <div style={{ textAlign: "center", marginTop: 24 }}>
            <ActionButton
              onClick={() => {
                modal.style.visibility = "inherit";
                setHelpOpen(false);
                setCurrentScreen(false);
              }}
              style={{ color: "white" }}
            >
              Start now
            </ActionButton>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export const HeatmapHelpModal = () => {
  const [isHelpOpen, setHelpOpen] = React.useState(true);

  return (
    <MyModal id="heatmapHelpModal" open={isHelpOpen}>
      <ModalContainer left="330px">
        <CloseButton onClick={() => setHelpOpen(false)}>X</CloseButton>
        <div style={{ padding: 24 }}>
          <Typography>Let's start with a “heatmap” calculation. (*)</Typography>

          <Typography style={{ marginTop: 12 }}>
            Step 1: You will have the choice to either select an entire country
            or draw a custom zone.
          </Typography>
          <Typography style={{ marginTop: 12 }}>
            Demo: click on “Country” and select one of the precalculated results
            via the load arrow on the right. (close this window first)
          </Typography>

          <Typography style={{ marginTop: 12 }}>
            (*) After completing the heatmap demo, toggle the slider to
            “locations” to explore this feature as well.
          </Typography>
        </div>
      </ModalContainer>
    </MyModal>
  );
};

export const LocationsHelpModal = () => {
  const [isHelpOpen, setHelpOpen] = React.useState(true);
  return (
    <MyModal open={isHelpOpen}>
      <ModalContainer left="330px">
        <CloseButton onClick={() => setHelpOpen(false)}>X</CloseButton>
        <div style={{ margin: 12 }}>
          <Typography>You've now selected a "location" calculation.</Typography>
        </div>
        <div style={{ margin: 12 }}>
          <Typography>
            You will have the choice to either select specific locations on the
            map or specify the coordinates.
          </Typography>
        </div>
        <div style={{ margin: 12 }}>
          <Typography>Demo: (small exercise)</Typography>
          <Typography>
            - Click on “Add a new location” and specify following coordinates
            (latitude: 45, longitude: 110).
          </Typography>
          <Typography>- Select wind as renewable technology.</Typography>
          <Typography>- Leave the financials on default (blank).</Typography>
          <Typography>
            - Remove any exclusion criteria as these are not applicable for this
            type of calculation.
          </Typography>
          <Typography>- Run the calculation.</Typography>
          <Typography>
            A demo report will be generated for this specific location and
            selected inputs.
          </Typography>
        </div>
      </ModalContainer>
    </MyModal>
  );
};

export const RenewableTechHelpModal = (props) => {
  const [isIntroText, setIsIntroText] = React.useState(true);
  const [isHelpOpen, setHelpOpen] = React.useState(true);

  const introText = (
    <>
      <Typography>
        Step 2: Fill in the different inputs (renewable technology, financials
        and exclusion criteria) in the panel on the left.
      </Typography>

      <Typography style={{ marginTop: 12 }}>
        Demo: the LCOE heatmap has loaded for your selected simulation. The
        legend of the different layers displayed and range in LCOE can be found
        on the right. You will now be guided through the inputs of the
        simulation in substeps 2a – 2e.
      </Typography>
      <Typography>
        (close this window first)
      </Typography>
    </>
  );

  const renewableTechText = (
    <>
      <Typography>
        Step 2a: Select the renewable technology (solar, wind or hybrid)."
      </Typography>
      <Typography style={{ marginTop: 12 }}>
        Demo: click on “Next” to proceed.
      </Typography>
    </>
  );

  return (
    <MyModal open={isHelpOpen}>
      <ModalContainer left={isIntroText ? false : "330px"}>
        <CloseButton
          onClick={() =>
            isIntroText ? setIsIntroText(false) : setHelpOpen(false)
          }
        >
          X
        </CloseButton>
        <div style={{ padding: 24 }}>
          {isIntroText ? introText : renewableTechText}
        </div>
      </ModalContainer>
    </MyModal>
  );
};

export const AdvancedLCOEHelpText = () => {
  const [isHelpOpen, setHelpOpen] = React.useState(true);

  return (
    <>
      <MyModal open={isHelpOpen}>
        <ModalContainer left="330px">
          <CloseButton onClick={() => setHelpOpen(false)}>X</CloseButton>
          <div style={{ padding: 24 }}>
            <Typography>
              Step 2b: Choose if a grid connection is required for your project
              (export of electricity vs selfconsumption). In complex and steep
              terrains, some additional CAPEX for site preparation can be
              defined based on the actual slope levels.
            </Typography>
          </div>
        </ModalContainer>
      </MyModal>

      <MyModal open={isHelpOpen}>
        <ModalContainer left="330px" top="350px">
          <div style={{ padding: 24 }}>
            <>
              <Typography>
                Step 2c: Define the financial assumptions for the selected
                technology (CAPEX, OPEX, lifetime, WACC). Country-specific default
                values are applied when left blank.
              </Typography>
              <Typography style={{ marginTop: 12 }}>
               Demo: click on “Next” to proceed.
              </Typography>
            </>
          </div>
        </ModalContainer>
      </MyModal>
    </>
  );
};

export const ExclusionAreasHelpModal = () => {
  const [isHelpOpen, setHelpOpen] = React.useState(true);
  return (
    <MyModal open={isHelpOpen}>
      <ModalContainer left="330px">
        <CloseButton onClick={() => setHelpOpen(false)}>X</CloseButton>

        <div style={{ padding: 24 }}>
          <>
            <Typography>
              Step 2d: Select the exclusion criteria for your project.
            </Typography>
            <Typography style={{ marginTop: 12 }}>
              Demo: click on “Next” to proceed.
            </Typography>
          </>
        </div>
      </ModalContainer>
    </MyModal>
  );
};

export const SummaryHelpModal = () => {
  const [isHelpOpen, setHelpOpen] = React.useState(true);
  return (
    <MyModal open={isHelpOpen}>
      <ModalContainer left="330px">
        <CloseButton onClick={() => setHelpOpen(false)}>X</CloseButton>

        <div style={{ padding: 24 }}>
          <Typography>
            Step 2e: Check all inputs prior to launching the calculation.
          </Typography>
          <Typography style={{ marginTop: 12 }}>
            Demo: click on “Run”.
          </Typography>
        </div>
      </ModalContainer>
    </MyModal>
  );
};

export const ParametersScreen = ({ isHelpOpen, dispatch }) => (
  <Modal
    hideBackdrop
    open={isHelpOpen}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <ModalContainer left="330px">
      <div
        onClick={() =>
          dispatch({
            type: "SET_HELP_MODAL",
            payload: "LOCATION",
          })
        }
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "end",
          padding: 12,
          marginBottom: -24,
          cursor: "pointer",
        }}
      >
        Next
      </div>
      <div style={{ padding: 24 }}>
        <Typography align="center">
          Please make sure to check all the selected parameters on the left
          panel
        </Typography>
        <Typography>&larr;</Typography>
      </div>
    </ModalContainer>
  </Modal>
);

export const PointHelpModal = () => {
  const [isHelpOpen, setHelpOpen] = React.useState(true);

  return (
    <MyModal open={isHelpOpen}>
      <ModalContainer left="50%">
        <CloseButton onClick={() => setHelpOpen(false)}>X</CloseButton>
        <div style={{ padding: 24 }}>
          <>
            <Typography >
              Step 3: Get detailed insight for specific locations (hourly capacity
              factors, LCOE analysis, etc).
            </Typography>

            <Typography style={{ marginTop: 12 }}>
              Demo: hover over the heatmap and see
              the change in average LCOE value along with details on the different
              layers. Next, select a random point on the map and explore the
              provided details in the panel on the right. Be sure to check out the
              Location Report as well. (close this window first)
            </Typography>
          </>
        </div>
      </ModalContainer>
    </MyModal>
  );
};

const LicensesScreen = ({ isLicensesOpen, setLicensesOpen }) => {
  return (
    <Modal
      open={isLicensesOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <LicensesContainer>
        <div
          style={{
            position: "absolute",
            right: "calc(20% - 10px)",
            borderRadius: 24,
            border: "2px solid rgb(128, 128, 128)",
            top: 90,
            background: "white",
            width: 24,
            textAlign: "center",
            zIndex: 2000,
          }}
          onClick={() => setLicensesOpen(false)}
        >
          X
        </div>
        <div style={{ display: "flex" }}>
          {Object.keys(licenses).map((license) => (
            <License
              license={licenses[license]}
              key={licenses[license].title}
            />
          ))}
        </div>
        <div style={{ padding: 12, width: "100%" }}>
          <LicenseButton
            href="https://digital.tractebel-engie.com/solutions/wisogis/"
            target="_blank"
            rel="noreferrer"
            style={{
              width: "100%",
              display: "flex",
              flex: 1,
              justifyContent: "center",
            }}
          >
            Buy it now
          </LicenseButton>
        </div>
      </LicensesContainer>
    </Modal>
  );
};

const WELCOME = "welcome";
const HISTORY = "history";

const PARAMETERS = "parameters";

const HelpModal = () => {
  const [isHelpOpen, setHelpOpen] = React.useState(true);
  const [isLicensesOpen, setLicensesOpen] = React.useState(false);
  const [currentScreen, setCurrentScreen] = React.useState(WELCOME);
  const dispatch = useDispatch();

  return (
    <>
      <ButtonContainer>
        <OpenLicensesButton
          href="https://digital.tractebel-engie.com/solutions/wisogis/"
          target="_blank"
          style={{ padding: "12px 65px" }}
        >
          Buy a license
        </OpenLicensesButton>
        <OpenLicensesButton
          style={{ padding: "12px 75px" }}
          onClick={() => {
            setLicensesOpen(false);
            setHelpOpen(!setHelpOpen);
            setCurrentScreen(HISTORY);
          }}
        >
          Use cases
        </OpenLicensesButton>
      </ButtonContainer>

      <LicensesScreen
        isLicensesOpen={isLicensesOpen}
        setLicensesOpen={setLicensesOpen}
      />

      {currentScreen === WELCOME ? (
        <WelcomeHelpModal
          isHelpOpen={isHelpOpen}
          setHelpOpen={setHelpOpen}
          currentScreen={currentScreen}
          setCurrentScreen={setCurrentScreen}
        />
      ) : null}

      {currentScreen === HISTORY ? (
        <History
          dispatch={dispatch}
          setHistoryVisible={() => {
            setCurrentScreen(PARAMETERS);
          }}
          toggleToSidePanel={() => {
            setCurrentScreen(PARAMETERS);
            dispatch(toggleSidePanel("renewable-sizing"));
          }}
        />
      ) : null}

      {/* {currentScreen === PARAMETERS ? (
        <ParametersScreen
          isHelpOpen={isHelpOpen}
          setHelpOpen={setHelpOpen}
          setCurrentScreen={setCurrentScreen}
        />
      ) : null} */}
    </>
  );
};

export default HelpModal;

const licenses = {
  locations: {
    title: "My locations",
    description:
      "Location comparison module with financial feasibility worldwide",
    blue: "",
    icon: faMapPin,
  },
  region: {
    title: "My region",
    description:
      "Generate heatmaps with financial indicators and exclusion areas for",
    blue: "1 region of your choice",
    icon: faMap,
  },
  worldwide: {
    title: "Worldwide",
    description:
      "Generate heatmaps with financial indicators and exclusion areas",
    blue: "everywhere in the world",
    icon: faGlobe,
  },
};

const LicenseContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  margin: 8px;
  padding: 12px;
  background-color: #eeeeee;
`;

const Logo = styled.div`
  height: 72px;
  width: 72px;
  border-radius: 50%;
  background-color: #009de9;
  text-align: center;
  margin: 0 auto;
`;

const Icon = styled(FontAwesomeIcon)`
  color: white;
  margin: auto;
  height: 72px;
`;

const Title = styled.h5`
  color: #009de9;
  font-weight: bold;
  padding: 12px 0;
  margin: 0;
`;

const Description = styled.span`
  padding: 0 0 12px 0;
`;

const BlueText = styled.span`
  color: #009de9;
`;

const License = ({ license }) => (
  <LicenseContainer>
    <Logo>
      <Icon icon={license.icon} />
    </Logo>
    <Title>{license.title}</Title>
    <Description>
      <span>{license.description}</span> <BlueText>{license.blue}</BlueText>
    </Description>
    <LicenseButton
      href={`mailto:${CONTACT_US_EMAIL}`}
      target="_blank"
      rel="noreferrer"
      onClick={() =>
        alert(
          "For any additional information or an extended demo, please contact us at support.wisogis@tractebel.engie.com"
        )
      }
    >
      Buy this license
    </LicenseButton>
  </LicenseContainer>
);

const LicenseButton = styled.a`
  color: white;
  font-weight: bold;
  text-align: center;
  background-color: #009de9;
  cursor: pointer;
  padding: 12px;
`;

const OpenLicensesButton = styled(LicenseButton)`
  color: white !important;
  margin: 10px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  transform: translate(-50%, 0);
  top: 20px;
  margin: auto;
  z-index: 2000;
  left: 50%;
`;

const LicensesContainer = styled.div`
  margin: auto;
  background-color: white;
  width: 60%;
  margin: auto;
  margin-top: 100px;
  padding: 16px;
  flex: 1;
`;
