import Processors from "kepler.gl/processors";

import heatmap from "../sampledata/scenario2_lebanon/heatmap.json";
import airports from "../sampledata/scenario2_lebanon/airports.json";
import complex_terrain from "../sampledata/scenario2_lebanon/complex_terrain.json";
import forest from "../sampledata/scenario2_lebanon/forest.json";
import power_lines from "../sampledata/scenario2_lebanon/power_lines.json";
import protected_area from "../sampledata/scenario2_lebanon/protected_area.json";
import urban from "../sampledata/scenario2_lebanon/urban.json";
import water from "../sampledata/scenario2_lebanon/water.json";
import wetland from "../sampledata/scenario2_lebanon/wetland.json";

import userInput from "../sampledata/scenario2_lebanon/demo2_input.json";

const HEATMAP = "heatmap";
const POWER_LINES = "power_lines";
const URBAN = "urban";
const AIRPORTS = "airports";
const PROTECTED_AREA = "protected_area";
const MILITARY = "military";
const HV_LINE_DISTANCE = "hv_line_distance";
const SUBSTATION_DISTANCE = "substation_distance";
const COMPLEX_TERRAIN = "complex_terrain";
const FOREST = "forest";
const WATER = "water";
const WETLAND = "wetland";

const getLayerLabelFromId = (id) => {
  switch (id) {
    case HEATMAP:
      return "Heatmap";
    case POWER_LINES:
      return "Power lines";
    case URBAN:
      return "Urban";
    case AIRPORTS:
      return "Airports";
    case PROTECTED_AREA:
      return "Protected area";
    case MILITARY:
      return "Military";
    case HV_LINE_DISTANCE:
      return "HV line distance";
    case SUBSTATION_DISTANCE:
      return "Substation distance";
    case COMPLEX_TERRAIN:
      return "Complex terrain";
    case FOREST:
      return "Forest";
    case WATER:
      return "Water";
    case WETLAND:
      return "Herbaceous Wetlands";
    default:
      return "";
  }
};

const loadDataSets = () => [
  {
    info: { label: getLayerLabelFromId("heatmap"), id: "heatmap" },
    data: Processors.processGeojson(heatmap),
  },
  {
    info: { label: getLayerLabelFromId("airports"), id: "airports" },
    data: Processors.processGeojson(airports),
  },
  {
    info: {
      label: getLayerLabelFromId("complex_terrain"),
      id: "complex_terrain",
    },
    data: Processors.processGeojson(complex_terrain),
  },
  {
    info: { label: getLayerLabelFromId("forest"), id: "forest" },
    data: Processors.processGeojson(forest),
  },
  {
    info: { label: getLayerLabelFromId("power_lines"), id: "power_lines" },
    data: Processors.processGeojson(power_lines),
  },
  {
    info: {
      label: getLayerLabelFromId("protected_area"),
      id: "protected_area",
    },
    data: Processors.processGeojson(protected_area),
  },
  {
    info: { label: getLayerLabelFromId("urban"), id: "urban" },
    data: Processors.processGeojson(urban),
  },
  {
    info: { label: getLayerLabelFromId("water"), id: "water" },
    data: Processors.processGeojson(water),
  },
  {
    info: { label: getLayerLabelFromId("wetland"), id: "wetland" },
    data: Processors.processGeojson(wetland),
  },
];

export const setMenuValuesLebanon1 = (dispatch) => {
  const layers = JSON.parse(userInput.layers);
  // const LCOE_params = JSON.parse(userInput.LCOE_params);

  dispatch({
    type: "SET_ZONE_OR_COUNTRY",
    payload: {
      name: userInput.name,
    },
  });
  dispatch({
    type: "SET_FORM_RENEWABLE_TECH",
    payload: {
      solar: userInput.opti !== "wind", //
      wind: userInput.opti !== "solar", //
      pv_tracking: userInput.pv_tracking ? "single" : "fixed",
      opti: userInput.opti, //
      combined_slider: userInput.combined_slider, //
    },
  });

  dispatch({
    type: "SET_FORM_EXCLUSION_AREAS",
    payload: {
      airports: layers.airports.N,
      complex_terrain: layers.complex_terrain.N,
      forest: layers.forest.N,
      power_lines: layers.power_lines.N,
      protected_area: layers.protected_area.N,
      urban: layers.urban.N,
      water: layers.water.N,
      wetland: layers.wetland.N,
      ui_show_complex_terrain: layers.complex_terrain.N,
      is_forest: !isNaN(layers.forest.N),
      is_airports: !isNaN(layers.airports.N),
      is_protected_area: !isNaN(layers.protected_area.N),
      is_urban: !isNaN(layers.urban.N),
    },
  });

  dispatch({
    type: "SET_FORM_ADVANCED_LCOE",
    payload: {
      grid_connection_type: userInput.grid_connection_type, //
      grid_cost_per_km: {
        checked: !!userInput.grid_cost_per_km, //
        value: userInput.grid_cost_per_km || "", //
      },
      slope_cost_per_percent: {
        checked: !!userInput.slope_cost_per_percent, //
        value: userInput.slope_cost_per_percent || "", //
      },
      // wind_capex: LCOE_params.wind_capex.N || "",
      // solar_capex: LCOE_params.solar_capex.N || "",
      // wind_opex: LCOE_params.wind_opex.N || "",
      // solar_opex: LCOE_params.solar_opex.N || "",
      // lifetime: LCOE_params.lifetime.N || "",
      // wacc: LCOE_params.wacc.N || "",
    },
  });
};
export default loadDataSets;
