import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable"; // https://www.npmjs.com/package/jspdf-autotable

import tractebelsolo from "../assets/tractebelsolo.png";
import wisogisblue from "../assets/wisogisblue.png";
import TopLeftCorner from "../assets/TopLeftCorner.png";
import BottomRightCorner from "../assets/BottomRightCorner.png";

import {
  getRenewableTechnologiesFormatted,
  getAdvancedLCOEFormatted,
  getExclusionAreasFormatted,
} from "./getSimulationParameters";

import latoRegular from "./Lato-Regular";
import latoBold from "./Lato-Bold";

const TITLE_SIZE = 32;
const SUBTITLE_SIZE = 24;
const BODY_SIZE = 16;
const SMALL_SIZE = 12;
const SMALLER_SIZE = 10;
const MINI_SIZE = 8;

const BLUE_TEXT_COLOR = "#40adf8";
const WHITE_TEXT_COLOR = "#ffffff";
const RED_TEXT_COLOR = "#bf1b28";
const BLACK_TEXT_COLOR = "#000000";
const DARK_TEXT_COLOR = "#180D59";
const LIGHT_TEXT_COLOR = "#aaaaaa";

const date = new Date();

const generatePDF = async (formReducer, showLegend) => {
  let legend_el = document.getElementsByClassName("map-legend")[0];
  if (!legend_el) {
    showLegend();
  }

  const doc = new jsPDF({ orientation: "l" });

  doc.addFileToVFS("Lato.ttf", latoRegular);
  doc.addFont("Lato.ttf", "Lato", "normal");

  doc.addFileToVFS("LatoBold.ttf", latoBold);
  doc.addFont("LatoBold.ttf", "Lato", "bold");

  doc.setFont("Lato", "normal");

  const map_el =
    document.getElementsByClassName("mapboxgl-map")[0].parentNode.parentNode;
  const layers_el = false; // document.getElementsByClassName("overlays")[0];

  doc.setFillColor(64, 173, 248);
  doc.setFontSize(TITLE_SIZE);

  doc.addImage(tractebelsolo, "PNG", 8, 4, 71, 15);
  doc.addImage(wisogisblue, "PNG", 240, 4, 48, 16);

  doc.rect(0, 30, doc.internal.pageSize.getWidth(), 80, "F");
  doc.addImage(TopLeftCorner, "PNG", 14, 42, 20, 20);
  doc.addImage(BottomRightCorner, "PNG", 270, 80, 20, 20);
  doc.setTextColor(WHITE_TEXT_COLOR);
  doc.text(32, 70, "Wisogis simulation report");
  doc.text(32, 85, "Heatmap");

  if (map_el) {
    let smaller_ratio, bigger_ratio, x, y;
    if (window.innerHeight <= window.innerWidth) {
      smaller_ratio = window.innerHeight;
      bigger_ratio = window.innerWidth;
      x = (bigger_ratio - smaller_ratio) / 2;
      y = 0;
    } else {
      smaller_ratio = window.innerWidth;
      bigger_ratio = window.innerHeight;
      x = 0;
      y = (bigger_ratio - smaller_ratio) / 2;
    }
    const map_canvas = await html2canvas(map_el, {
      width: window.innerWidth,
      height: window.innerWidth / 5,
      x: 0,
      y: window.innerWidth / 3,
    });
    const map_image = map_canvas.toDataURL("image/png");

    doc.addImage(
      map_image,
      "JPEG",
      0,
      110,
      doc.internal.pageSize.getWidth(),
      doc.internal.pageSize.getWidth() / 5
    );
  }

  doc.setFont("Lato", "bold");
  doc.setFontSize(SMALL_SIZE);
  doc.setTextColor(RED_TEXT_COLOR);
  doc.text(8, 180, "RESTRICTED");
  doc.setTextColor(BLUE_TEXT_COLOR);
  doc.text(
    8,
    190,
    `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
  );
  doc.setTextColor(BLACK_TEXT_COLOR);
  // doc.text(8, 196, "Author");
  doc.setFont("Lato", "normal");

  doc.addPage();

  if (map_el) {
    let smaller_ratio, bigger_ratio, x, y;
    if (window.innerHeight <= window.innerWidth) {
      smaller_ratio = window.innerHeight;
      bigger_ratio = window.innerWidth;
      x = (bigger_ratio - smaller_ratio) / 2;
      y = 0;
    } else {
      smaller_ratio = window.innerWidth;
      bigger_ratio = window.innerHeight;
      x = 0;
      y = (bigger_ratio - smaller_ratio) / 2;
    }
    const map_canvas = await html2canvas(map_el, {
      width: smaller_ratio,
      height: smaller_ratio,
      x,
      y,
    });

    const map_image = map_canvas.toDataURL("image/png");
    doc.addImage(map_image, "PNG", 2, 2, 206, 206);

    if (layers_el) {
      const layer_canvas = await html2canvas(layers_el, {
        width: smaller_ratio,
        height: smaller_ratio,
        x,
        y,
      });

      const layer_image = layer_canvas.toDataURL("image/png");
      doc.addImage(layer_image, "PNG", 2, 2, 100, 100);
    }

    if (!legend_el) {
      legend_el = document.getElementsByClassName("map-legend")[0];
    }
    if (legend_el) {
      const legend_canvas = await html2canvas(legend_el);
      const legend_image = legend_canvas.toDataURL("image/png");
      doc.setFillColor(245, 245, 245);
      doc.rect(208, 2, 84, doc.internal.pageSize.getHeight() - 4, "F");
      doc.addImage(legend_image, "JPEG", 208, 2);
    }
  }

  doc.addPage();

  doc.addImage(wisogisblue, "PNG", 8, 8, 48, 16);

  doc.setTextColor(BLUE_TEXT_COLOR);
  doc.setFontSize(SUBTITLE_SIZE);
  doc.text(16, 38, "Introduction");

  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.setFontSize(MINI_SIZE);
  doc.text(
    20,
    50,
    "This report presents all the results of the simulations performed with the Wisogis tool, developed by "
  );
  doc.text(
    20,
    54,
    "the company Tractebel. The tool's renewable energy production models are based on high-resolution, "
  );
  doc.text(
    20,
    58,
    "state-of-the-art climate models combined with operational data from renewable assets around the "
  );
  doc.text(
    20,
    62,
    "world. In addition, Tractebel's extensive expertise in site selection and financial optimization of"
  );
  doc.text(
    20,
    66,
    "renewable projects has enabled the tool to develop an accurate and up-to-date financial model. This "
  );
  doc.text(
    20,
    70,
    "approach provides reliable preliminary results to secure project decision-making, helping you to "
  );
  doc.text(20, 74, "choose the most suitable site.");
  doc.text(20, 78, "More information on the methodology available:");
  doc.setTextColor(BLUE_TEXT_COLOR);

  doc.textWithLink("wisogis.tractebel.com/documentation", 20, 82, {
    url: "https://wisogis.tractebel.com/documentation",
  });

  doc.setTextColor(BLACK_TEXT_COLOR);

  doc.setFontSize(BODY_SIZE);
  doc.text(20, 96, "Abbreviations");
  doc.setFontSize(SMALL_SIZE);

  const abbreviationsData = [
    {
      abb: "GHI",
      fullname: "Global Horrizontal Irradiance",
      explanation:
        "Average annual, monthly or daily sum of global horizontal irradiation in kWh/m",
    },
    {
      abb: "LCOE",
      fullname: "Levelized Cost of Electricity",
      explanation:
        "Full price of electricity over the lifetime of the renewable plant in $/kWh",
    },

    {
      abb: "CAPEX",
      fullname: "Capital Expenditure",
      explanation:
        "Initial investment required to build the generating asset in $/kW",
    },

    {
      abb: "OPEX",
      fullname: "Operationnal Expenditure",
      explanation:
        "Operationnal & Maintenance costs during the lifetime of the generating asset in $/kW/yr",
    },

    {
      abb: "WACC",
      fullname: "Weighted Average Cost of Capital",
      explanation:
        "Discount rate used to perform a discounted cash flow analysis of the energy project.",
    },
  ];

  var abbreviationsColumns = [
    { title: "ABB.", dataKey: "abb" },
    { title: "FULL NAME", dataKey: "fullname" },
    { title: "EXPLANATION", dataKey: "explanation" },
  ];

  doc.autoTable(abbreviationsColumns, abbreviationsData, {
    tableWidth: 135,
    styles: {
      fillColor: [255, 255, 255],
      lineColor: 240,
      textColor: [42, 42, 42],
      lineWidth: 0,
      minCellHeight: "15",
      fontSize: 8,
    },
    headStyles: {
      fontStyle: "normal",
    },
    columnStyles: {
      abb: { cellWidth: 20 },
      fullname: { cellWidth: 35 },
      explanation: { cellWidth: 80 },
    },
    margin: { top: 100 },
  });

  doc.setTextColor(LIGHT_TEXT_COLOR);
  doc.setFontSize(SUBTITLE_SIZE);
  doc.text(150, 36, "Simulation parameters summary");

  const {
    renewableTechFormData,
    advancedLCOEFormData,
    exclusionAreasFormData,
  } = formReducer;

  const renewableTechnParams = getRenewableTechnologiesFormatted(
    renewableTechFormData
  );
  const advancedLCOEParams = getAdvancedLCOEFormatted(advancedLCOEFormData);
  const exclusionAreasParams = getExclusionAreasFormatted(
    exclusionAreasFormData
  );

  let parametersColumns = [
    { title: "", dataKey: "name" },
    { title: "", dataKey: "selection", halign: "right" },
  ];

  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.setFontSize(BODY_SIZE);
  doc.text(160, 50, "Renewable technologies");

  doc.autoTable(parametersColumns, renewableTechnParams, {
    pageBreak: "avoid",
    rowPageBreak: "avoid",
    horizontalPageBreak: false,
    tableWidth: 50,
    startY: 55,
    styles: {
      overflow: "hidden",
      theme: "plain",
      fillColor: [255, 255, 255],
      lineColor: 240,
      textColor: [42, 42, 42],
      lineWidth: 0,
      fontSize: 8,
    },
    margin: { left: 160 },
    columnStyles: {
      name: { fillColor: false, cellWidth: 60 },
      selection: { fillColor: false, halign: "right", cellWidth: 60 },
    },
  });

  if (advancedLCOEParams.length) {
    doc.setTextColor(BLACK_TEXT_COLOR);
    doc.setFontSize(BODY_SIZE);
    doc.text(160, 85, "Advanced LCOE");

    doc.autoTable(parametersColumns, advancedLCOEParams, {
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      horizontalPageBreak: false,
      tableWidth: 50,
      startY: 90,
      styles: {
        overflow: "hidden",
        theme: "plain",
        fillColor: [255, 255, 255],
        lineColor: 240,
        textColor: [42, 42, 42],
        lineWidth: 0,
        fontSize: 8,
      },
      margin: { left: 160 },
      columnStyles: {
        name: { fillColor: false, cellWidth: 60 },
        selection: { fillColor: false, halign: "right", cellWidth: 60 },
      },
    });
  }

  if (exclusionAreasParams.length) {
    doc.setTextColor(BLACK_TEXT_COLOR);
    doc.setFontSize(BODY_SIZE);
    doc.text(160, 145, "Exclusion Areas");

    doc.autoTable(parametersColumns, exclusionAreasParams, {
      pageBreak: "avoid",
      rowPageBreak: "avoid",
      horizontalPageBreak: false,
      tableWidth: 50,
      startY: 150,
      styles: {
        overflow: "hidden",
        theme: "plain",
        fillColor: [255, 255, 255],
        lineColor: 240,
        textColor: [42, 42, 42],
        lineWidth: 0,
        fontSize: 8,
      },
      margin: { left: 160 },
      columnStyles: {
        name: { fillColor: false, cellWidth: 60 },
        selection: { fillColor: false, halign: "right", cellWidth: 60 },
      },
    });
  }

  doc.setFontSize(SMALL_SIZE);
  doc.setTextColor(BLUE_TEXT_COLOR);
  doc.text(14, 198, "Disclaimer");

  doc.setFontSize(MINI_SIZE);
  doc.setTextColor(BLACK_TEXT_COLOR);
  doc.text(
    14,
    202,
    "Tractebel S.A. cannot be held liable for any direct, incidental, consequential, indirect or punitive damages resulting or alleged to result from the use of the report provided."
  );
  doc.text(14, 206, "More details: ");
  doc.setTextColor(BLUE_TEXT_COLOR);
  doc.textWithLink("wisogis.tractebel.com/documentation", 31, 206, {
    url: "https://wisogis.tractebel.com/documentation",
  });

  // Page 4
  doc.addPage();

  doc.setFillColor(64, 173, 248);
  doc.rect(2, 2, 293, 170, "F");
  doc.addImage(TopLeftCorner, "PNG", 14, 14);
  doc.addImage(BottomRightCorner, "PNG", 250, 128);

  doc.setTextColor(WHITE_TEXT_COLOR);
  doc.setFontSize(SMALL_SIZE);
  doc.setFont("Lato", "bold");

  doc.text(
    20,
    120,
    "Tractebel is a global engineering company delivering game-changing solutions for a carbon-neutral future. Insights gathered "
  );
  doc.text(
    20,
    126,
    "during our more than 150 years of experience in energy, urban, nuclear and water projects combined with local expertise "
  );
  doc.text(
    20,
    132,
    "allow us to tackle complex future-oriented projects. By connecting strategy, design, engineering and project management, "
  );
  doc.text(
    20,
    138,
    "our community of 5,000 imaginative experts helps companies and public authorities create positive impact towards a "
  );
  doc.text(
    20,
    144,
    "sustainable world, where people, planet and profit collectively thrive. With offices in Europe, Africa, Asia, the Middle East "
  );
  doc.text(
    20,
    150,
    "and Latin America, the company registered a turnover of 671 million."
  );

  doc.setTextColor(DARK_TEXT_COLOR);
  doc.setFontSize(SMALLER_SIZE);

  doc.text(8, 180, "TRACTEBEL ENGINEERING S.A.");

  doc.setTextColor(BLACK_TEXT_COLOR);

  doc.text(8, 184, "Wisogis");

  doc.text(8, 188, "Boulevard Simon Bolivar 34-36");

  doc.text(8, 192, "tractebel-engie.com");

  doc.text(8, 200, "wisogis@tractebel.engie.com");

  // File outpout file
  doc.save("Wisogis results.pdf");
};

export default generatePDF;
