import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import Check from "../assets/check.svg";

const StyledIcon = styled.div`
  color: white;
  border: 12px solid #12ff12;
  color: white;
  border-radius: 24px;
  font-size: 12px;
  background: #12ff12;
`;

const Image = styled.img`
  height: 24px;
  width: 24px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  z-index: 10000000;
  position: fixed;
  background-color: #ffffff10;
  backdrop-filter: blur(1px);
`;

const Card = styled.div`
  background: white;
  margin: auto;
  height: 300px;
  width: 600px;
  text-align: center;
  padding: 24px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 20%;
`;

const Button = styled.button`
  border: 0;
  background-color: #009de9;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 14px;
  margin: 12px auto;
  padding: 12px 24px;
  pointer-events: all;
  transition: all 0.3s ease 0s;
`;

const SimulationLaunchedComp = (props) => {
  if (!props.loadingReducer.isApiLoadingHeatmap) return null;

  return (
    <Wrapper>
      <Card>
        <StyledIcon>
          <Image src={Check} />
        </StyledIcon>
        <Typography variant="h5" style={{ fontWeight: "800" }}>
          Simulation launched
        </Typography>
        <Typography>
          The simulation has just been sent in calculation, you will receive a
          notification when the calculation is complete (~5min). During this
          time, you can browse the tool or start another simulation.
        </Typography>
        <Button
          onClick={() =>
            props.dispatch({ type: "SET_API_LOADING_HEATMAP", payload: false })
          }
        >
          Back to the tool
        </Button>
      </Card>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingReducer: state.loadingReducer,
  };
};
const dispatchToProps = (dispatch) => ({ dispatch });

export const SimulationLaunched = connect(
  mapStateToProps,
  dispatchToProps
)(SimulationLaunchedComp);

export default SimulationLaunched;
