import React from "react";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap, faGlobe, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { CONTACT_US_EMAIL } from "../../constants";

const licenses = {
  locations: {
    title: "My locations",
    description:
      "Location comparison module with financial feasibility worldwide",
    blue: "",
    icon: faMapPin,
  },
  region: {
    title: "My region",
    description:
      "Generate heatmaps with financial indicators and exclusion areas for",
    blue: "1 region of your choice",
    icon: faMap,
  },
  worldwide: {
    title: "Worldwide",
    description:
      "Generate heatmaps with financial indicators and exclusion areas",
    blue: "everywhere in the world",
    icon: faGlobe,
  },
};

const LicenseContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  margin: 8px;
  padding: 12px;
  background-color: #eeeeee;
`;

const Logo = styled.div`
  height: 72px;
  width: 72px;
  border-radius: 50%;
  background-color: #009de9;
  text-align: center;
  margin: 0 auto;
`;

const Icon = styled(FontAwesomeIcon)`
  color: white;
  margin: auto;
  height: 72px;
`;

const Title = styled.h5`
  color: #009de9;
  font-weight: bold;
  padding: 12px 0;
  margin: 0;
`;

const Description = styled.span`
  padding: 0 0 12px 0;
`;

const BlueText = styled.span`
  color: #009de9;
`;

const License = ({ license }) => (
  <LicenseContainer>
    <Logo>
      <Icon icon={license.icon} />
    </Logo>
    <Title>{license.title}</Title>
    <Description>
      <span>{license.description}</span> <BlueText>{license.blue}</BlueText>
    </Description>
    <LicenseButton
      href={`mailto:${CONTACT_US_EMAIL}`}
      target="_blank"
      rel="noreferrer"
      onClick={() =>
        alert(
          "For any additional information or an extended demo, please contact us at support.wisogis@tractebel.engie.com"
        )
      }
    >
      Buy this license
    </LicenseButton>
  </LicenseContainer>
);

const LicenseButton = styled.a`
  color: white;
  font-weight: bold;
  text-align: center;
  background-color: #009de9;
  cursor: pointer;
  padding: 12px;
`;

const OpenLicensesButton = styled(LicenseButton)`
  position: absolute;
  top: 20px;
  margin: auto;
  z-index: 2000;
  left: 50%;
  transform: translate(-50%, 0);
  color: white !important;
`;

const LicensesContainer = styled.div`
  margin: auto;
  background-color: white;
  width: 60%;
  margin: auto;
  margin-top: 100px;
  padding: 16px;
  flex: 1;
`;

const LicensesModal = () => {
  const [isLicensesOpen, setLicensesOpen] = React.useState(false);

  return (
    <OpenLicensesButton
      href="https://digital.tractebel-engie.com/solutions/wisogis/"
      target="_blank"
    >
      Buy a license
    </OpenLicensesButton>
  );
};

export default LicensesModal;
