import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Icons } from "kepler.gl/components";
import { toggleSidePanel } from "kepler.gl/actions";
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Title,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Stack, Animation } from "@devexpress/dx-react-chart";
import { Typography } from "@material-ui/core";
import HelpText from "./HelpText";
import data_ireland from "../sampledata/scenario1_ireland/data_ireland";
import data_lebanon1 from "../sampledata/scenario2_lebanon/data_lebanon";
import data_lebanon2 from "../sampledata/scenario3_lebanon2/data_lebanon";
import data_chile from "../sampledata/scenario4_chile/data_chile";

const Root = (props) => (
  <Legend.Root
    {...props}
    sx={{ display: "flex", margin: "auto", flexDirection: "row" }}
  />
);
const Label = (props) => (
  <Legend.Label {...props} sx={{ whiteSpace: "nowrap" }} />
);

const WIDTH = 400;

const SheetContainer = styled.div`
  background-color: #f7f7f7;
  min-width: ${WIDTH}px;
  display: block;
  right: 12px;
  padding: 12px;
  z-index: 10;
  margin-top: 0px;
  position: absolute;
  bottom: 136px;
  border-radius: 4px;
  color: #6a7485;
`;

const DataRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GRAPH = "GRAPH";
const LCOE_ANALYSIS = "LCOE_ANALYSIS";
const ADDITIONAL_INFOS = "ADDITIONAL_INFOS";

const MonthlyGraph = ({ data, graphData, setHourly }) => {
  let chartId = null;

  if (data.wind_monthly_capacity_factor && data.solar_monthly_capacity_factor)
    chartId = "hybrid";
  else if (data.wind_monthly_capacity_factor) chartId = "wind";
  else if (data.solar_monthly_capacity_factor) chartId = "solar";

  return (
    <Chart
      data={graphData}
      height={300}
      width={WIDTH}
      id={`${chartId}-capacity-factor-0`}
    >
      <ArgumentAxis />
      <ValueAxis />

      {data.wind_monthly_capacity_factor ? (
        <BarSeries
          name="Wind monthly capacity factor"
          valueField="wind_monthly_capacity_factor"
          argumentField="month"
          color="#00A3FF"
        />
      ) : null}
      {data.solar_monthly_capacity_factor ? (
        <BarSeries
          name="Solar monthly capacity factor"
          valueField="solar_monthly_capacity_factor"
          argumentField="month"
          color="#FF8B00"
        />
      ) : null}
      <Animation />
      <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
      <Title
        textComponent={({ text }) => (
          <Typography
            onClick={() => setHourly(true)}
            variant="h5"
            style={{
              fontSize: "1em",
              margin: "0px auto 5px auto",
              fontWeight: "500",
            }}
          >
            {text}
          </Typography>
        )}
        text="< Monthly Capacity Factor >"
      />
      <Stack
        stacks={[
          {
            series: [
              "Wind monthly capacity factor",
              "Solar monthly capacity factor",
            ],
          },
        ]}
      />
    </Chart>
  );
};

const HourlyGraph = ({ data, graphData, setHourly }) => {
  let chartId = null;

  if (data.wind_hourly_capacity_factor && data.solar_hourly_capacity_factor)
    chartId = "hybrid";
  else if (data.wind_hourly_capacity_factor) chartId = "wind";
  else if (data.solar_hourly_capacity_factor) chartId = "solar";

  return (
    <Chart
      data={graphData}
      height={300}
      width={WIDTH}
      id={`${chartId}-hourly-capacity-factor-0`}
    >
      <ArgumentAxis />
      <ValueAxis />

      {data.wind_hourly_capacity_factor ? (
        <BarSeries
          name="Wind hourly capacity factor"
          valueField="wind_hourly_capacity_factor"
          argumentField="hour"
          color="#00A3FF"
        />
      ) : null}
      {data.solar_hourly_capacity_factor ? (
        <BarSeries
          name="Solar hourly capacity factor"
          valueField="solar_hourly_capacity_factor"
          argumentField="hour"
          color="#FF8B00"
        />
      ) : null}
      <Animation />
      <Legend position="bottom" rootComponent={Root} labelComponent={Label} />
      <Title
        textComponent={({ text }) => (
          <Typography
            onClick={() => setHourly(false)}
            variant="h5"
            style={{
              fontSize: "1em",
              margin: "0px auto 5px auto",
              fontWeight: "500",
            }}
          >
            {text}
          </Typography>
        )}
        text="< Hourly Capacity Factor >"
      />
      <Stack
        stacks={[
          {
            series: [
              "Wind hourly capacity factor",
              "Solar hourly capacity factor",
            ],
          },
        ]}
      />
    </Chart>
  );
};

const LCOEAnalysis = ({ data }) => (
  <div style={{ height: 300, width: WIDTH, paddingTop: 12 }}>
    <Typography variant="subtitle2" align="center" style={{ fontSize: 16 }}>
      LCOE Analysis
    </Typography>
    <hr />
    {data.wind_monthly_capacity_factor ? (
      <>
        <Typography>Wind CAPEX: {data.wind_capex} $/kW</Typography>
        <Typography>Wind OPEX: {data.wind_opex} $/kW/year</Typography>
      </>
    ) : null}
    <br />
    {data.solar_monthly_capacity_factor ? (
      <>
        <Typography>Solar CAPEX: {data.solar_capex} $/kW</Typography>
        <Typography>Solar OPEX: {data.solar_opex} $/kW/year</Typography>
      </>
    ) : null}
    <br />
    {data.grid_distance ? (
      <DataRow>
        <Typography>
          Distance to the nearest HV line: {data.grid_distance.toFixed(2)} km
        </Typography>
      </DataRow>
    ) : null}
    <DataRow>
      <HelpText element="Slope">
        <Typography>Slope: {data.slope.toFixed(2)} %</Typography>
      </HelpText>
    </DataRow>
  </div>
);

const AdditionalInfos = ({
  data: { electricity_tarif, electricity_ranking, grid_connection_time },
}) => (
  <div style={{ height: 300, width: WIDTH, paddingTop: 12 }}>
    <Typography variant="subtitle2" align="center" style={{ fontSize: 16 }}>
      Additional information about the location
    </Typography>
    <hr />
    <DataRow>
      <Typography>Electricity tarif: {electricity_tarif}$/MWh</Typography>
    </DataRow>
    <DataRow>
      <Typography>
        Getting Electricity ranking: {electricity_ranking}
      </Typography>
    </DataRow>
    <DataRow>
      <Typography>
        Typical time for a grid connection: {grid_connection_time} days
      </Typography>
    </DataRow>
  </div>
);

const CapacityGraph = ({ data, isHourly, setHourly }) => {
  let monthlyGraphData = [
    { month: "Ja" },
    { month: "Fe" },
    { month: "Mr" },
    { month: "Ap" },
    { month: "My" },
    { month: "Jn" },
    { month: "Jl" },
    { month: "Au" },
    { month: "Se" },
    { month: "Oc" },
    { month: "Nv" },
    { month: "De" },
  ];
  if (data.wind_monthly_capacity_factor) {
    monthlyGraphData = monthlyGraphData.map((g, i) => ({
      wind_monthly_capacity_factor: data.wind_monthly_capacity_factor[i],
      ...g,
    }));
  }
  if (data.solar_monthly_capacity_factor) {
    monthlyGraphData = monthlyGraphData.map((g, i) => ({
      solar_monthly_capacity_factor: data.solar_monthly_capacity_factor[i],
      ...g,
    }));
  }

  let hourlyGraphData = [
    { hour: "00" },
    { hour: "01" },
    { hour: "02" },
    { hour: "03" },
    { hour: "04" },
    { hour: "05" },
    { hour: "06" },
    { hour: "07" },
    { hour: "08" },
    { hour: "09" },
    { hour: "10" },
    { hour: "11" },
    { hour: "12" },
    { hour: "13" },
    { hour: "14" },
    { hour: "15" },
    { hour: "16" },
    { hour: "17" },
    { hour: "18" },
    { hour: "19" },
    { hour: "20" },
    { hour: "21" },
    { hour: "22" },
    { hour: "23" },
  ];

  if (data.wind_hourly_capacity_factor) {
    hourlyGraphData = hourlyGraphData.map((g, i) => ({
      wind_hourly_capacity_factor: data.wind_hourly_capacity_factor[i],
      ...g,
    }));
  }
  if (data.solar_hourly_capacity_factor) {
    hourlyGraphData = hourlyGraphData.map((g, i) => ({
      solar_hourly_capacity_factor: data.solar_hourly_capacity_factor[i],
      ...g,
    }));
  }

  return (
    <HelpText element="Capacity Factor">
      <div>
        {isHourly ? (
          <HourlyGraph
            data={data}
            graphData={hourlyGraphData}
            setHourly={setHourly}
          />
        ) : (
          <MonthlyGraph
            data={data}
            graphData={monthlyGraphData}
            setHourly={setHourly}
          />
        )}
      </div>
    </HelpText>
  );
};

const Sheet = (props) => {
  const { coordinate } = props;
  const longitude = coordinate[0];
  const latitude = coordinate[1];

  const [data, setData] = React.useState();
  const [bottomInfos, setBottomInfos] = React.useState(GRAPH);
  const [isHourly, setHourly] = React.useState(false);

  React.useEffect(() => {
    if (props.formReducer.zoneSelectionData.name === "Demo scenario 1")
      setData(data_ireland);
    else if (props.formReducer.zoneSelectionData.name === "Demo scenario 2")
      setData(data_lebanon1);
    else if (props.formReducer.zoneSelectionData.name === "Demo scenario 3")
      setData(data_lebanon2);
    else if (props.formReducer.zoneSelectionData.name === "Demo scenario 4")
      setData(data_chile);
  }, [coordinate]);

  if (!data) return null;

  let href = "";

  if (props.formReducer.zoneSelectionData.name === "Demo scenario 1")
    href = "./Wisogis location report Ireland.pdf";
  else if (props.formReducer.zoneSelectionData.name === "Demo scenario 2")
    href = "./Wisogis location report Lebanon1.pdf";
  else if (props.formReducer.zoneSelectionData.name === "Demo scenario 3")
    href = "./Wisogis location report Lebanon2.pdf";
  else if (props.formReducer.zoneSelectionData.name === "Demo scenario 4")
    href = "./Wisogis location report Chile.pdf";

  return (
    <SheetContainer>
      <div
        style={{ position: "absolute", right: 12, top: 12 }}
        onClick={() => {
          props.dispatch({
            type: "@@kepler.gl/MAP_CLICK",
          });
        }}
      >
        X
      </div>
      <Typography variant="h6" align="center">
        Selected Location
      </Typography>
      <DataRow>
        <Typography>Coordinates:</Typography>
        <Typography>
          {latitude.toFixed(6)}, {longitude.toFixed(6)}
        </Typography>
      </DataRow>
      {!!data.mix &&
      data.wind_monthly_capacity_factor &&
      data.solar_monthly_capacity_factor ? (
        <DataRow>
          <HelpText element="Mix">
            <Typography>Mix:</Typography>
          </HelpText>
          <Typography>
            {(data.mix * 100).toFixed(2)}% Wind /{" "}
            {((1 - data.mix) * 100).toFixed(2)}% Solar
          </Typography>
        </DataRow>
      ) : null}
      <DataRow style={{ marginBottom: 6 }}>
        <Typography>LCOE:</Typography>
        <Typography>{data.LCOE.toFixed(3)} $/MWh</Typography>
      </DataRow>

      {data.wind_monthly_capacity_factor ? (
        <DataRow>
          <Typography>Wind capacity factor:</Typography>
          <Typography>
            {(data.wind_capacity_factor * 100).toFixed(2)}%
          </Typography>
        </DataRow>
      ) : null}
      {data.solar_monthly_capacity_factor ? (
        <DataRow>
          <Typography>Solar capacity factor:</Typography>
          <Typography>
            {(data.solar_capacity_factor * 100).toFixed(2)}%
          </Typography>
        </DataRow>
      ) : null}

      <div style={{ height: 16 }} />

      {bottomInfos === GRAPH ? (
        <CapacityGraph data={data} isHourly={isHourly} setHourly={setHourly} />
      ) : null}

      {bottomInfos === LCOE_ANALYSIS ? <LCOEAnalysis data={data} /> : null}
      {bottomInfos === ADDITIONAL_INFOS ? (
        <AdditionalInfos data={data} />
      ) : null}

      <DataRow>
        {bottomInfos === GRAPH ? <div /> : null}
        {bottomInfos === GRAPH ? (
          <PrevNextButton onClick={() => setBottomInfos(LCOE_ANALYSIS)}>
            Next &rarr;
          </PrevNextButton>
        ) : null}
        {bottomInfos === LCOE_ANALYSIS ? (
          <PrevNextButton onClick={() => setBottomInfos(GRAPH)}>
            &larr; Previous
          </PrevNextButton>
        ) : null}
        {bottomInfos === LCOE_ANALYSIS ? (
          <PrevNextButton onClick={() => setBottomInfos(ADDITIONAL_INFOS)}>
            Next &rarr;
          </PrevNextButton>
        ) : null}
        {bottomInfos === ADDITIONAL_INFOS ? (
          <PrevNextButton onClick={() => setBottomInfos(LCOE_ANALYSIS)}>
            &larr; Previous
          </PrevNextButton>
        ) : null}
        {bottomInfos === ADDITIONAL_INFOS ? <div /> : null}
      </DataRow>
      <div style={{ display: "flex" }}>
        <ActionButton
          href={href}
          target="_blank"
          onClick={() => {
            setHourly(false);
          }}
        >
          <Icons.Save height="16px" color="white" />
          <ActionButtonText>Location Report</ActionButtonText>
        </ActionButton>
      </div>
      <ActionButton
        onClick={() => {
          props.dispatch(toggleSidePanel("zone"));
          props.dispatch({
            type: "SET_HEATMAP_OR_LOCATIONS",
            payload: true,
          });
          props.dispatch({
            type: "ADD_LOCATION",
            payload: { lng: longitude, lat: latitude },
          });
        }}
      >
        <Icons.Clipboard height="16px" color="white" />
        <ActionButtonText>Add for comparison</ActionButtonText>
      </ActionButton>
    </SheetContainer>
  );
};

const PrevNextButton = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 4px;
  cursor: pointer;
`;

const ActionButtonText = styled.span`
  margin-left: 4px;
  color: white;
  &::selection {
    background-color: #009de9 !important;
  }
`;

const ActionButton = styled.a`
  align-items: center;
  background-color: #009de9;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: bold;
  justify-content: center;
  margin: 6px;
  padding: 12px 24px;
  pointer-events: all;
  text-align: center;
  text-decoration: none;
  &::selection {
    background-color: #009de9 !important;
  }
  &:hover {

  text-decoration: none;
  }
}
`;

const LocationDetails = (props) => {
  // const clicked = mockData;
  const clicked = props.keplerReducer?.map?.visState?.clicked;
  const polygons = clicked?.object?.geometry?.coordinates[0];

  if (!clicked || !props.keplerReducer?.map?.visState?.datasets?.heatmap)
    return null;

  const { coordinate } = clicked;
  // const { LCOE } = clicked.object.properties;

  return <Sheet polygons={polygons} coordinate={coordinate} {...props} />;
};

const mapStateToProps = (state) => {
  return {
    formReducer: state.formReducer,
    uiStateReducer: state.uiStateReducer,
    appReducer: state.appReducer,
    keplerReducer: state.keplerGl,
  };
};

const dispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, dispatchToProps)(LocationDetails);
